 <template>
   
	<div style='display:block; border: 1pt solid green; height: 100%;'>
		
		<div style='display: table-row'>
			<div class="field1">
				Channel
			</div>
			<div v-for="(s,si) in feedLimits.slots" :key="'s'+si" class="field2">
												
											
				<InputTime v-model="s.from" @changed="updateLimits()"/> - <InputTime @changed="updateLimits()" v-model="s.to" :min="s.from"/>
						
			</div>
			<div class="field2">Other (NPT)</div>
			<div class="field2">Total ({{targetType.name}})</div>
									
		</div>
		<div style='display: table-row'>
					<div class="fieldH1">
						{{targetType.name}}
					</div>
					<div v-for="(s,si) in feedLimits.slots" :key="'s'+si" class="fieldH2">
														
						{{formatNumber(actuals.actuals.filter( p=>p.slot===s.no).map(i=>parseFloat(i[ target])).reduce((a,b)=>a+b))}} /						
						{{formatNumber(feedLimits.limits.filter( p=>p.slot===s.no).map(i=>parseFloat(i[ target]?i[ target]:0)).reduce((a,b)=>a+b))}}
								
					</div>
					<div class="fieldH2">
																	
						{{formatNumber(actuals.actuals.filter( p=>p.slot===999).map(i=>parseFloat(i[ target])).reduce((a,b)=>a+b))}} /										
						{{formatNumber(feedLimits.limits.filter( p=>p.slot===999).map(i=>parseFloat(i[ target]?i[ target]:0)).reduce((a,b)=>a+b))}}
								
					</div>	
					<div class="fieldH2">
																						
						{{formatNumber(actuals.actuals.map(i=>parseFloat(i[ target])).reduce((a,b)=>a+b))}} /															
						{{formatNumber(feedLimits.limits.map(i=>parseFloat(i[ target]?i[ target]:0)).reduce((a,b)=>a+b))}} 
								
					</div>			
				</div>
				<div style='display: table-row; height: 6pt;'>
				
				</div>
		<div style='display: block; height: 75%; max-height: 75%; overflow-y: auto'>
		<div style='display: table-row' v-for="(w,wi) in channels" :key="'w'+wi" >
			<div class="field1">
				{{w.label}}
			</div>
			<div v-for="(s,si) in feedLimits.slots" :key="'s'+si" class="field2">
				<span v-for="(l,li) in feedLimits.limits.filter( p=>p.channelId===w.id && p.slot===s.no)" :key="'l1'+li" >
					
				<span v-for="(a,ai) in actuals.actuals.filter( p=>p.channelId===w.id && p.slot===s.no)" :key="'a1'+ai">
					<span v-if="(a[ target] - l[ target]) > .01" style="color: red; font-size: 10pt; font-weight: bold">
						+{{formatNumber(a[ target])}}
					</span>
					<span v-else-if="(l[ target] - a[ target]) > .01" style="color: green; font-size: 10pt; font-weight: bold">
						-{{formatNumber(a[ target])}}
					</span>
					<span v-else style="color: #888; font-size: 10pt;">
						{{formatNumber(a[ target])}}
					</span>
				</span>
				<InputInt @changed="updateLimits()" v-model="l[ target]"/>
				</span>
			</div>	
			<div v-for="(s,si) in feedLimits.slots" :key="'s'+si" class="field2">
				
				<span v-for="(l,li) in feedLimits.limits.filter( p=>p.channelId===w.id && p.slot===999)" :key="'l2'+li" >
				<span v-for="(a,ai) in actuals.actuals.filter( p=>p.channelId===w.id && p.slot===999)" :key="'a2'+ai">
					<span v-if="(a[ target] - l[ target]) > .01" style="color: red; font-size: 10pt; font-weight: bold">
						+{{formatNumber(a[ target])}}
					</span>
					<span v-else-if="(l[ target] - a[ target]) > .01" style="color: green; font-size: 10pt; font-weight: bold">
						-{{formatNumber(a[ target])}}
					</span>
					<span v-else style="color: #888; font-size: 10pt;">
					{{formatNumber(a[ target])}}
					</span>
				</span>
				<InputInt @changed="updateLimits()" v-model="l[ target]"/>
				</span>	
			</div>	
			
			<div v-for="(s,si) in feedLimits.slots" :key="'s'+si" class="field2">
				   {{formatNumber(actuals.actuals.filter( p=>p.channelId===w.id).map(i=>parseFloat(i[ target])).reduce((a,b)=>a+b))}} /
				   {{formatNumber(feedLimits.limits.filter( p=>p.channelId===w.id).map(i=>parseFloat(i[ target]?i[ target]:0)).reduce((a,b)=>a+b))}} 
					
			</div>	
			
		</div>
		</div>
		
		<button title="save" :style="getAppStyle()+'float: right;'" class='button myButton' @click="saveLimits(feedLimits)">SAVE</button>
		<button title="set values to 0" :style="getAppStyle()+'float: right;'" class='button myButton' @click="zeroLimits(feedLimits)">0</button>
		
		<button v-if="placementId" :title="'copy totals to target'" :style="getAppStyle()+'float: right;'" class='button myButton' @click="$emit('target', feedLimits.limits.map(i=>parseFloat(i[ target]?i[ target]:0)).reduce((a,b)=>a+b))"><mdicon size="18" name="arrow-up-bold-circle-outline"/></button>
		<button v-if="actuals && actuals.actuals" :title="'copy values from actuals'" :style="getAppStyle()+'float: right;'" class='button myButton' @click="reachedLimits(feedLimits, actuals)"><mdicon size="18" name="arrow-right-bold-circle-outline"/></button>
		<button :title="'set values to '+avg" :style="getAppStyle()+'float: right;'" class='button myButton' @click="avgLimits(feedLimits, avg)">{{avg}}</button>
		<button :title="'set values to '+avgUp" :style="getAppStyle()+'float: right;'" class='button myButton' @click="avgLimits(feedLimits, avgUp)">{{avgUp}}</button>
		<button title="set empty (blank) values to 0" :style="getAppStyle()+'float: right;'" class='button myButton' @click="emptyLimits(feedLimits)"><mdicon size="18" name="trash-can"/></button>
		<button title="reload" :style="getAppStyle()+'float: right;'" class='button myButton' @click="loadData()"><mdicon size="18" name="reload"/></button>
	</div>	
	
	
</template>

<script>
import {HTTP, fwAPI, userAPI, invAPI, bngAPI, opsAPI, reportAPI, placementServiceAPI, setReload, formatNumber,showError} from '@/variables.js';
import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { formatPercent, format } from '@/Numbers.js';
import InputTime from '@/components/inputElements/InputTimeHHMM';
import InputInt from '@/components/booking/InputInt';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
//import InputFieldS from '@/components/booking/InputFieldS';
import AIndividualChart from '@/components/AIndividualChart';
import Switch from '@/components/Switch';
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
export default {
  name: "FEEDLIMITS",
   components : {
	 InputTime, InputInt
	 // InputFieldS, AIndividualChart,
	 // 'app-switch': Switch
  },
  props: {
	  value: {},
	  targetType: {},
	  mainTarget: { type: Number, default: 0},
	  placementId:{ type: Number, default: 0},
	  budget: { type: Number, default: 0},
	  actualsIn: {},
	  spots: { type: Number, default: 0},
	  fromEditor: { type: Boolean, default: false}
  },
  data(){
    return {
    	formatPercent, getAppStyle, format, formatNumber,
    	feedLimits: this.value,
		avg: 0,
		avgUp: 0,
		actuals: {},
		channels: [],
		target: "spots"
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
		fmt( val)
    	{
       		return formatNumber( val );
    	},
	   saveLimits( limits)
	   { 
		let that = this;
		limits.base = this.target.toUpperCase();
		HTTP.post( placementServiceAPI+"/saveFeedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId, limits)
        .then( response => 
        { 
			that.feedLimits = response.data;
			//alert( JSON.stringify( that.feedLimits))
        	that.$toast.success("Channel limits saved", 'Ok', { timeout: 1000, position: "topRight" });
        }).catch(e => {
        	showError( that.$toast, "save limits", e)
        });

	   },
	   zeroLimits( limits)
   	   { 
		let copy = [...limits.limits];
   		for ( let l in copy)
		{
			let limit = copy[ l];
			if ( !limit[ this.target])
			{
				limit[ this.target] = 0;
			}
		}
		limits.limits = copy;
		//alert( JSON.stringify( limits.limits));
   	   },
	   reachedLimits( limits, actuals )
	   {
			let copy = [...limits.limits];
	   		for ( let l in copy)
			{
				let limit = copy[ l];
				let actual = actuals.actuals[l]
				if ( actual[ this.target])
				{
					limit[ this.target] = parseFloat(formatNumber(actual[ this.target]+0.005));
				}
				else
				{
					limit[ this.target] = 0;
				}
			}
			limits.limits = copy;
	   },
	   emptyLimits( limits)
	   { 
		    let copy = [...limits.limits];
	      	for ( let l in copy)
	   		{
	   			let limit = limits.limits[ l];
	   			limit[ this.target] = null;
	   		}
			limits.limits = copy;
	   		//alert( JSON.stringify( limits.limits));
	    },
		avgLimits( limits, newLim)
	    { 
		    let copy = [...limits.limits];
	      	for ( let l in copy)
	   		{
	   			let limit = limits.limits[ l];
	   			limit[ this.target] = newLim;
	   		}
			limits.limits = copy;
	   		//alert( JSON.stringify( limits.limits));
	   },
	   reload() {
		   this.feedLimits = this.value
		   this.$forceUpdate();
	   },
	   updateLimits()
	   {
			this.feedLimits.base = this.target.toUpperCase();
			this.$emit('input', this.feedLimits);
			this.$emit('changed', this.feedLimits);
			
	   },
	   loadData() {
		   let that = this;
		   this.target = (this.targetType.spots) ? "spots" : (this.targetType.grp) ? "grp" : (this.targetType.budget) ? "budget": "spots";
		   this.avg = this.mainTarget / 
		   HTTP.post( placementServiceAPI+"/createFeedLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId)
	        .then( response => 
	        { 
				that.feedLimits = response.data.limits;
				that.channels = response.data.channels;
				that.actuals = response.data.actuals;
				that.feedLimits.base = that.target.toUpperCase();
				if ( that.target!=='spots')
				{
					that.avg = Math.round( 100*that.mainTarget / 2 / that.channels.length)/100;
				}
				else
				{
					that.avg = Math.round( that.mainTarget / 2 / that.channels.length + 1.5);
				}
				that.avgUp = Math.round( that.mainTarget / 2 / that.channels.length + .5);
				that.channels.sort((a,b)=>a.label.localeCompare(b.label))
	        	//that.$toast.success("Channel limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
	        }).catch(e => {
	        	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
	        });
	   },
	   equals( a, b)
	   {
		 return JSON.stringify(a) == JSON.stringify(b)  
	   },
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  	 this.feedLimits= this.value;
	 this.loadData(); 
  },
   watch:
   {
	  value:  function() { 
		  		this.feedLimits = this.value;
		  		//this.loadData();   
			  },
	  actualsIn: function() {
		this.actuals = this.actualsIn;
	  },
	  feedLimits: function() { 
		if (!this.equals(this.value, this.feedLimits)) 
		{
			this.$emit('input', this.feedLimits);
			this.$emit('changed', this.feedLimits);
		}
	  }
   }
}
</script>
<style  scoped>
.dontWrapIB {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-block;
  height: 23pt;
  padding-top: 1pt;
}
.smallText {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextSlider {
  width: 60%;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonXS {
	//width: 18%;
    padding: 0px 0px;
    outline: none;
    border-radius: 3px;
    height: 16pt;
    font-size: 10pt;
    background-color: #eef;
    border: none;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}  
.fieldLeft {
	display: block; 
	float: left;
	min-width: 66pt !important;
	max-width: 66pt !important;
	font-size: 9pt;
	height: 18pt;
	overflow: hidden;
	white-space: nowrap; /* Don't forget this one */
}
.field {
	display: inline-flex; 
	width: 30pt;
	font-size: 9pt;
	height: 12pt;
}
.fieldXS {
	display: inline-flex; 
	font-size: 9pt;
	height: 12pt;
}
.fieldRow {
	display: block; 
	
	font-size: 9pt;
	height: 12pt !important;
}
.field1 {
	display: table-cell; 
	text-align: left;
	font-weight: bold; 
	width: 50pt;
	font-size: 9pt;
	height: 12pt;
}
.fieldH1 {
	display: table-cell; 
	text-align: left;
	font-weight: bold; 
	width: 50pt;
	font-size: 9pt;
	height: 12pt;
	border-bottom: 1pt solid blue;
}
.field2 {
	display: table-cell; 
	text-align: right;
	width: 80pt;
	max-width: 80pt;
	height: 16pt;
	font-size: 9pt;
	height: 12pt;
}
.fieldH2 {
	display: table-cell; 
	text-align: right;
	width: 80pt;
	max-width: 80pt;
	height: 16pt;
	font-size: 9pt;
	height: 12pt;
	border-bottom: 1pt solid blue;
}
.field3 {
	display: inline; 
	width: 10pt;
}
.numeric {
	text-align: right;
}
.inp {
	border: 1px solid #aaa;
}
.noInp {
	border: none;
}
textarea {
  resize: none;
}
</style>