<template>
 <div style='width: 100%; float: top;  background-color: white;'>
    <div style='width: 24%; float: left;'>
  
    
     <div v-if="getOpenThisModule()">
     <!--
     <GFWTreeView v-if='treeHeight && placementIDs.length < 2 && placementIDs.length!==1' :openModule="getOpenThisModule()" :modules="[ 'Placement', 'Campaign', 'GridDetail']" 
        @Placement="setPlacementList" 
        @Campaign="setCampaign" 
        @GridDetail="setGrid"
        :height=treeHeight
        :date=setDate
        @click='closeDetails'
     >
     </GFWTreeView>
    -->
     
     </div>
     
       
     <div v-if='placementIDs.length' class='xselection' 
          style='float: left; height: calc(100vH - 114px); overflow-y: hidden; display: inline-block; border-radius: 6px; box-shadow: 1pt 1pt 4pt 1pt #888; padding-left: 0pt; width: 99% !important; margin-top: 2pt;'>
       <!-- CONTROLS PLACEMENT/INFO -->
           
           <div v-if='placementIDs.length===1' class='BMappHead' style='float: left; display: block; width: 100% !important;'>
              <div v-if='placement.id'  class="colored" style='width: 100% !important; height: 32pt !important;' >
	              <div v-if='placement.id' style='float: left; width: 82%; display: block; font-size: 9pt;'>
	                <div class='dontWrap90 smallTextHeader' :title="tr('Campaign')+': ' + info.campaign.name"
	                     style='color: #000; text-align: left;' @click="openEntity($event, 'Placement', placement.id)">
	                {{info.campaign.name}}</div>
	                <div class='dontWrap90 smallTextHeader' :title="tr('Placement')+': ' + placement.name"
	                     style='color: #000; text-align: left;' @click="$refs.editor.open('Placement', placement.id)">
	                {{placement.name}}</div>
	              </div>
	              <div style='float: right;'  @click='closeScreen($event)'>
             			<WindowCloseBN/>
             	 </div>
             	
              </div>
             </div> <!-- appHead -->
             <div v-else class='BMappHead' style='float: left; display: block; width: 100% !important;'>
              <div class="colored" style='width: 100% !important; height: 32pt !important;'>
              <div v-if='placement.id' style='text-align: left; display: flex; font-size: 9pt;'>
              {{placementIDs.length}} Placements
              (to select a single placement use the context-menu and chose one of the placements from the spot-list)
              </div>
              </div>
             </div> <!-- appHead -->
     
        
    	<div style='padding-left: 5pt; height: 98%; overflow-y: hidden; padding-right: 5pt; width: 100%; margin-top: 2pt;'>
    	    <div class="BMSection" :style="getBGStyle2()+'margin-top: 6pt;'">
	              <span class='BMSectionDetail dontWrap90 smallText60' ><b>INFO  {{info.placementType?('('+info.placementType.name+' '+tr('Placement')+')'):''}}</b></span>
	        </div>
			
			<table border="0" width="100%" style="font-size: 9pt;">
				<tr>
					<td colspan="1" class="bold">Target: {{info.targetType.name}} {{(info.placement.freeSpotsShareTarget)?"+":""}}</td>
					
					<td style="text-align: right" colspan="1" v-if="info.targetType.budget">{{format2(info.placement.target)}} </td>
					<td style="text-align: right" colspan="1" v-else>{{info.placement.target}}</td>
					<td class="right">
						<div style="display: block; width: 100%;">
						<GProgress v-if="info.targetType.spots && info.placement.target"       treshold="95" :percentage="getPercent(markedSpots+inventoryPlacementResult.spotsPlacementTotal-inventoryPlacementResult.spotsPlacementTotalFree, {}, info.placement.targetTotal )"/>
						<GProgress v-else-if="info.targetType.budget && info.placement.target" treshold="90" :percentage="getPercent(inventoryPlacementResult.budgetPlacementTotal/(0.01*info.placement.payPercentage), {}, info.placement.budget )"/>
						<GProgress v-else-if="info.targetType.grp && info.placement.freeSpotsShareTarget && info.placement.target"    treshold="80" :percentage="getPercent(inventoryPlacementResult.grpPlacementTotal+markedGRP, {}, info.placement.targetTotal )"/>
						<GProgress v-else-if="info.targetType.grp && !info.placement.freeSpotsShareTarget && info.placement.target"    treshold="80" :percentage="getPercent(inventoryPlacementResult.grpPlacementPaid+forPaid(markedGRP), {}, info.placement.targetTotal )"/>
						</div>
					</td>
					
				</tr>
							
				<tr>
					<td colspan="1"></td>
				    <td colspan="1" class="bold" style="text-align: right;">Paid</td>
					<td colspan="1" class="bold" style="text-align: right;">Free</td>
				</tr>
				<tr>
					<td colspan="1" class="bold">Spots</td>
					<td colspan="1" style="text-align: right;">{{ forPaid(markedSpots) + inventoryPlacementResult.spotsPlacementTotal-inventoryPlacementResult.spotsPlacementTotalFree}}</td>
					<td colspan="1" style="text-align: right;">{{ forFree(markedSpots) + inventoryPlacementResult.spotsPlacementTotalFree}}</td>
				</tr>
				<tr>
					<td colspan="1" class="bold">Budget ({{format2(info.placement.budget)}})</td>
					<td colspan="1" style="text-align: right;">{{format2(inventoryPlacementResult.budgetPlacementTotal+forPaid(markedBudget))}}</td>
					<td colspan="1" style="text-align: right;">{{format2(inventoryPlacementResult.mediaBudgetPlacementTotal+forFree(markedBudget)-inventoryPlacementResult.budgetPlacementTotal)}}</td>
				</tr>
				<tr>
					<td colspan="1" class="bold">Ratecard/Media-Value</td>
					<td colspan="1" style="text-align: right;">{{format2(inventoryPlacementResult.mediaBudgetPlacementTotal+markedBudget)}}</td>
				</tr>
				<tr v-if="placement.targetgroupId">
					<td colspan="1" class="bold">GRP</td>
					<td colspan="1" style="text-align: right;">{{format2(inventoryPlacementResult.grpPlacementPaid+forPaid(markedGRP))}}</td>
					<td colspan="1" style="text-align: right;">{{format2(inventoryPlacementResult.grpPlacementTotal-inventoryPlacementResult.grpPlacementPaid+forFree(markedGRP))}}</td>
				</tr>
				
				<tr v-if="placement.targetgroupId && inventoryPlacementResult.grpPlacementTotal || markedGRP">
					<td colspan="1" class="bold">CPP</td>
					<td v-if="info.placement.freeSpotsShareTarget" colspan="1" style="text-align: right;">
						  {{format2((inventoryPlacementResult.budgetPlacementTotal+markedBudget)/(inventoryPlacementResult.grpPlacementTotal+markedGRP))}}
						  /
						  ({{format2((inventoryPlacementResult.budgetPlacementTotal+markedBudget)/(inventoryPlacementResult.grpPlacementPaid+markedGRP))}}) 
						 
						  </td>
					<td v-else colspan="1" style="text-align: right;">{{format2(inventoryPlacementResult.budgetPlacementTotal/(inventoryPlacementResult.grpPlacementPaid+markedGRP))}}</td>
					<td v-if="inventoryPlacementResult.grpPlacementTotal > inventoryPlacementResult.grpPlacementPaid" colspan="1" style="text-align: right;">{{format2((inventoryPlacementResult.mediaBudgetPlacementTotal-inventoryPlacementResult.budgetPlacementTotal)/(inventoryPlacementResult.grpPlacementTotal-inventoryPlacementResult.grpPlacementPaid+markedGRP))}}</td>
					<td v-else colspan="1" style="text-align: right;"> - </td>
				</tr>
				<tr v-else>
					<td colspan="1" class="bold">CPP</td>
					<td v-if="info.placement.freeSpotsShareTarget" colspan="1" style="text-align: right;">
						  -
						  </td>
					<td v-else colspan="1" style="text-align: right;">-</td>
					<td v-if="inventoryPlacementResult.grpPlacementTotal > inventoryPlacementResult.grpPlacementPaid" colspan="1" style="text-align: right;"> - </td>
					<td v-else colspan="1" style="text-align: right;"> - </td>
				</tr>
				<tr>
					<td colspan="1">Free ratio (target/actual)</td>
					<td colspan="1" style="text-align: right;">{{info.incentiveDiscount}}%</td>
					<td v-if="inventoryPlacementResult.budgetPlacementTotal" colspan="1" style="text-align: right;">{{ Math.round(100*(inventoryPlacementResult.mediaBudgetPlacementTotal-inventoryPlacementResult.budgetPlacementTotal)/(inventoryPlacementResult.budgetPlacementTotal+markedBudget))}}%</td>
				</tr>
								
			</table>
			
	    <!--		
        <div class="BMSection" :style="getBGStyle2()+'margin-top: 8pt;'">
              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER</b></span>
        </div>
		-->
        <div v-if='spotSearchFilter' style='color: red' @click='spotSearchFilter=null'>
        	Special Filter: active
        </div>
		<div v-else style='height: 8pt;'>
		        	
		</div>
		
    	<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
    	<span class='smallText bold'>Date range:</span>
    	<div style='float: right; width: 80%; display: inline-block;'>
	    	<div style='float: left; width: calc(45%);'>
	    	<InputDatePick :auto='false' v-model="fromDate" @enter="getAdBreaksNew(placement)"/>
	    	</div>
	
	    	<div style='float: right; width: calc(45%); color: #000;'>
	    	<InputDatePick :auto='false' v-model="untilDate" @enter="getAdBreaksNew(placement)"/>
	    	</div>     
    	</div>
    	
        </div>
    	
        <div v-if='placementIDs.length===1' @dragover.prevent="openUpload($event)">
            
	        <div class="BMSection" :style="'border: none; border-bottom: 1px solid grey;; margin-top: 18pt; width: 100%; !important'">
	            <div style='display: inline-block; float: left; width: 100%; padding-left: 1pt;'>
	            <div class='folderStyle' :active='settingsType===ty'            
	                  v-for="(type,ty) in settingsLabels" :key="'set'+ty" @click='settingsType=ty' :title="settingsTooltip[ty]">
	                 
						  <div v-if="settingsIcons[ty]" :style="'width: 20pt; color: '+getIcon( ty ).color">
						  						
	  						<mdicon color="#aaa" :width="16" :name="getIcon( ty ).label" />
	  						
	  						
	  	                  </div>
		                 
		                  <div v-else class='smallTextTab' style='width: 30pt;'>
		                   {{type}}
		                  </div>
	           		
	            </div>	
	            </div>
	         </div>
	        <div v-if="settingsLabels[settingsType]==='Book'" class="settingsBlock">
	           
	           <div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
		    	<span class='smallText bold'>Weekdays:</span>
		    	<InputWeekdays style='float: right;' v-model="currentWeekdays" fontsize='9pt' width='80%'/>    
		        </div>
		    	<div v-if='placementIDs.length===1 && breakTypeData && breakTypeData.length > 1' class="dontWrapIB" style='height: 21pt; width: 100%;'>
		    	<span class='smallText bold'>Breaktypes:</span>
		    	<GSelect :appendToBody="true" style='width: 80%; float: right; font-size: 10pt;' :options="breakTypeData" v-model="plcmBreakTypes" :multiple="true" @input="setBreakTypes">  
		    	   <template #option="{ id, label }">
		      					<span z-index="999">{{label}}</span>
		    				 </template>
		        </GSelect>
		        </div>
		        
	           
	            <div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
		    	<span class='smallText bold'>{{tr("BusinessType")}}:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<GSelect style='width: 92%' :appendToBody="true" :options="businessTypes"  v-model="businessType" :multiple="false" >  
		    	  
			        </GSelect>
			        <button v-if="businessType && businessType.id" class='myButtonLight button' :style="getAppStyle()+'float: right;  margin: 0pt; height:22pt;'" @click="$refs.editor.open('BusinessType', businessType.id)"><mdicon  :width="16" name="pencil" /></button>
		    	</div>
		    	</div>
		    	
		    	<div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
		    	<span class='smallText bold'>{{tr("Product")}}:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<GSelect style='width: 92%' :appendToBody="true" :options="productData" v-model="product" :multiple="false" @input="setProduct">  
			        </GSelect>
			        <button v-if="product && product.id" class='myButtonLight button' :style="getAppStyle()+'float: right;  margin: 0pt; height:22pt;'" @click="$refs.editor.open('Product', product.id)"><mdicon  :width="16" name="pencil" /></button>
		    	</div>
		    	</div>
		        
		        <div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
		    	<span class='smallText bold'>{{tr("Duration")}}:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<InputField v-model="duration"/>
			    </div>
		    	</div>
		        
		        <div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
		    	<span class='smallText bold'>{{tr("Copy")}}:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<GSelect style='width: 92%' :options="copyData" v-model="copy" :multiple="false" @input="setCopy">  
		    	 
		             </GSelect>
		             <button v-if="copy && copy.id" class='myButtonLight button' :style="getAppStyle()+'float: right;  margin: 0pt; height:22pt;'" @click="$refs.editor.open('Copy', copy.id)"><mdicon  :width="16" name="pencil" /></button>
		    	</div>
		    	</div>
		    	
		    	<div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
		    	<span class='smallText bold'>{{tr("Rotation")}}:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<GSelect style='width: 92%' :appendToBody="true" :options="rotationData" v-model="rotation" :multiple="false" @input="setRotation">  
	
		            </GSelect>
		             <button v-if="rotation && rotation.id" class='myButtonLight button' :style="getAppStyle()+'float: right;  margin: 0pt; height:22pt;'" @click="$refs.editor.open('Rotation', rotation.id)"><mdicon  :width="16" name="pencil" /></button>
		    	</div>
		    	</div>
		       
		        <div  class="dontWrapIB" style='height: 23pt; width: 100%;'>
		    	<span class='smallText bold'>Pricing:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<div>
			    		<InputField  v-model="placement.price"/>
			    		<div class='smallText60 bold' style='padding-left: 5pt;'>
			    		({{info.priceType.name}})
			    		</div>
			    	</div>

		    	</div>
		    	</div>
		    	
		    	<div  class="dontWrapIB" style='height: 23pt; margin-top: 12pt; width: 100%;'>
		    	<span class='smallText bold'>{{tr("BookStatus")}}:</span>
		    	<div class="dontWrapIB" style='float: right; width: 80%; display: inline-flex;'>
			    	<GSelect style='width: 92%' :appendToBody="true" :options="allBookStatus" v-model="selectedBookStatus" :multiple="false">  
	
		            </GSelect>
		             <button v-if="selectedBookStatus && selectedBookStatus.id" class='myButtonLight button' :style="getAppStyle()+'float: right;  margin: 0pt; height:22pt;'" @click="$refs.editor.open('BookStatus', selectedBookStatus.id)"><mdicon  :width="16" name="pencil" /></button>
		    	</div>
		    	</div>
				<div v-if='placementIDs.length===1' class="dontWrapIB"  style='height: 21pt; width: 100%;'> 
		        <span class='smallText bold'>TargetGroup:</span>
		        <GSelect v-if='placementIDs.length===1' style='width: 80%; float: right; font-size: 10pt;' :appendToBody="true" :options="allTargetGroups" v-model="targetGroup" :multiple="false" @input="setTargetGroup">  
			
				</GSelect>
				</div>
		       
	        </div>

			<!-- ----------------------------------------- -->
			<!-- UPLOAD                                   -->
			<!-- ----------------------------------------- -->
			<div v-else-if="settingsLabels[settingsType]==='upl'" class="transitionOptimizer settingsBlock" id='uploader' 
					 		@drop="dropFile($event, 'uploader');"
						    @dragenter.prevent="hoverLine( 'uploader')"
						    @dragleave.prevent="leaveLine( 'uploader')"		        
							@dragover.prevent="hoverLineMain( 'uploader')" >
							
				<div style="padding-top: 3pt;"><b>Briefing (booking-requests) upload</b></div>
				<div v-if="uploadData && uploadData.type && uploadData.type==='NONE'">
					no valid format detected. 
				</div>	
				<div v-else-if="bookBriefingsSummary && bookBriefingsSummary.errors && bookBriefingsSummary.errors.length">
					<div style='font-size: 11pt; font-weight: bold; color: red;'>not imported:</div>
					<div v-for="(err,ei) in bookBriefingsSummary.errors" :key="'err'+ei" style='font-size: 9pt;'>
						{{ err}}
					</div>
				</div>
				
				<div v-else-if="uploadData && uploadData.name">
					<div style='height: 16pt;' :title="uploadData.name">
						<span class='smallText bold'>{{uploadData.type}}</span><span class='smallText3x'>{{uploadData.name}}</span>
					</div>
					<div v-if="bookBriefingsSummary">
						<div style='height: 16pt;'>
							<span class='smallText bold'>Booked:</span><span class='smallText3x'><b>{{fmt(bookBriefingsSummary.valid)}} ({{fmt(bookBriefingsSummary.bookingsPaid)}} + {{fmt(bookBriefingsSummary.bookingsFree)}})</b></span>
						</div>
						<div v-if="Math.abs(bookBriefingsSummary.paid-bookBriefingsSummary.paidRequested) > 100" style='height: 16pt;'>
							<span class='smallText bold'>Paid:</span><span class='smallText3x'><b style='color: red;'>{{fmt(bookBriefingsSummary.paid)}} {{info.currency.code}}</b> of {{fmt(bookBriefingsSummary.paidRequested)}}</span>
						</div>
						<div v-else style='height: 16pt;'>
							<span class='smallText bold'>Paid:</span><span class='smallText3x'><b>{{fmt(bookBriefingsSummary.paid)}} {{info.currency.code}}</b> of {{fmt(bookBriefingsSummary.paidRequested)}}</span>
						</div>	
						<div v-if="Math.abs(bookBriefingsSummary.free-bookBriefingsSummary.freeRequested) > 100" style='height: 16pt;'>
							<span class='smallText bold'>Free:</span><span class='smallText3x'><b style='color: red;'>{{fmt(bookBriefingsSummary.free)}} {{info.currency.code}}</b> of {{fmt(bookBriefingsSummary.freeRequested)}} ({{fmt(100.0*bookBriefingsSummary.free/bookBriefingsSummary.paid)}}%)</span>
						</div>
						<div v-else style='height: 16pt;'>
							<span class='smallText bold'>Free:</span><span class='smallText3x'><b>{{fmt(bookBriefingsSummary.free)}} {{info.currency.code}}</b> of {{fmt(bookBriefingsSummary.freeRequested)}} ({{fmt(100.0*bookBriefingsSummary.free/bookBriefingsSummary.paid)}}%)</span>
						</div>	
						
						
					</div>
					<div v-else>
						<table width="100%" style='font-size:9pt;'>
							<tr><td width="30%">Valid lines</td><td>{{uploadData.valid}}</td></tr>
							<tr><td>Channels</td><td>{{uploadData.medias.join()}}</td></tr>
							<tr><td>Durations</td><td>{{uploadData.durations.join()}}</td></tr>
							<tr><td>Period</td><td><b>{{uploadData.from}}</b> - <b>{{uploadData.to}}</b></td></tr>
							<tr><td>Advertiser/Product</td><td><b>{{uploadData.client}}</b>, Product: <b>{{uploadData.product}}</b></td></tr>
							<tr><td>Fits</td><td><app-switch v-model="uploadData.placementFits" :checked="uploadData.placementFits"/></td></tr>
						</table>
						
		
				    </div>
					<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
			            <span class='smallTextSlider bold'>Time (in ms): {{(briefingOpt.time)}}ms</span>
			            <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
						<vue-slider :disabled='!uploadData || !uploadData.valid' :marks=false min=500 :max="20000" v-model="briefingOpt.time"></vue-slider>
						</div>
				    </div>
					<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
			            <span class='smallTextSlider bold'>Weekly tolerance: {{(briefingOpt.weekTolerance)}}%</span>
			            <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
						<vue-slider :disabled='!uploadData || !uploadData.valid' :marks=false min=0 :max="50" v-model="briefingOpt.weekTolerance"></vue-slider>
						</div>
				    </div>
					<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
			            <span class='smallTextSlider bold'>Paid plus: {{(briefingOpt.addPaid)}} {{info.currency.code}}</span>
			            <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
						<vue-slider :disabled='!uploadData || !uploadData.valid' :marks=false min=0 :max="100" v-model="briefingOpt.addPaid"></vue-slider>
						</div>
				    </div>
					<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
			            <span class='smallTextSlider bold'>Free plus: {{(briefingOpt.addFree)}} {{info.currency.code}}</span>
			            <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
						<vue-slider :disabled='!uploadData || !uploadData.valid' :marks=false min=0 :max="1000" v-model="briefingOpt.addFree"></vue-slider>
						</div>
				    </div>
					<!--
					<span class='smallText bold' style='width: 50%;'>Write Inputfile:</span>
					<app-switch :disabled='!uploadData || !uploadData.valid' v-model="briefingOpt.printInput" :checked="briefingOpt.printInput"/>
					-->
				</div>
				<div v-else style='padding-top: 50pt; display: block; text-align: center; vertical-align: middle; align-items: center; justify-content: center; height: 100%; width: 100; border: 2pt solid #fcd;'>
					<span v-if="!formData || !formDataName" style='color: #aaa;'>drop booking file</span>
					<span v-else style='color: #aaa; font-size: 9pt;'>loading {{formDataName}}...</span>
				</div>
				
				
				<div style='display: table; position: absolute; bottom: 0; width: 100%;'>
					<button title="add bookings (book on top of existing bookings)" :disabled='!uploadData || !uploadData.valid || !uploadData.placementFits' :style="getAppStyle()+'float: right;'" class='button myButton' @click='bookBriefings(uploadData.validLines, "add")'><mdicon :width="20" name="playlist-plus"/></button>
					<button title="replace bookings (clean the days before booking)" :disabled='!uploadData || !uploadData.valid || !uploadData.placementFits' :style="getAppStyle()+'float: right;'" class='button myButton' @click='bookBriefings(uploadData.validLines, "replace")'><mdicon :width="20" name="playlist-remove"/></button>
					<button title="reload input file" :disabled='!formData || !formDataName' class='button myButton' :style="getAppStyle()+'float: right;'" @click='uploadData=null; upload(formData, placement, formDataName)'><mdicon :width="20" name="reload" /></button>
										
					<button title="download booking summary" :disabled='!bookBriefingsSummary || !bookBriefingsSummary.file' class='button myButton' :style="getAppStyle()+'float: right;'" @click='downloadBriefingReport()'><mdicon :width="20" name="download" /></button>
					<button title="show summary of uploaded briefing" :disabled='!uploadData' :style="getAppStyle()+'float: left;'" class='button myButton' @click='$refs.briefingView.open(uploadData, placement, briefingOpt);'><mdicon :width="20" name="information-variant"/></button>				
										
				</div>
			</div>
	        
	        <!-- OPTIMIZER  -->
	        <div v-else-if="settingsLabels[settingsType]==='Opt'" class="transitionOptimizer settingsBlock">
	            <div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
	            <span class='smallTextSlider bold'>Time (in ms) for Optimisation: {{(optimParam.timeOptMax)}}ms</span>
	            <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
				<vue-slider :marks=false min=2000 max=60000 v-model="optimParam.timeOptMax"></vue-slider>
				</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
	            <span class='smallTextSlider bold'>Time (in ms) (unimproved): {{(optimParam.timeUnimpr)}}ms</span>
	            <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
				<vue-slider :marks=false min=50 :max="Math.round(optimParam.timeOptMax*.8)" v-model="optimParam.timeUnimpr"></vue-slider>
				</div>
				</div>
				<!-- 
				<span class='smallText bold' style='width: 50%; color: #888;'>Create data file</span>
				<app-switch  v-model="optimParam.writeData" :checked="optimParam.writeData"/>
				 -->
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
				<span class='smallText bold' style='width: 50%;'>Keep bookings:</span>
				<app-switch v-model="optimParam.keepBookings" :checked="optimParam.keepBookings"/>
				</div>

				<span class='smallText bold' style='width: 50%;'>Perform bookings</span>
				<app-switch :disabled="optimParam.writeData" v-model="optimParam.performBookings" :checked="optimParam.performBookings"/>

				<div v-if='info.targetType.grp' class="dontWrapIB" style='height: 21pt; width: 100%;'>
	            <span class='smallTextSlider bold'>Safety {{optimParam.safety}}%</span>
	            <div class="dontWrapIB" style='float: right; padding-right: 5pt; padding-top: 3pt; width: 40%; display: inline-block;'>
				<vue-slider :marks=false min=0 :max="100" v-model="optimParam.safety"></vue-slider>
				</div>
				</div>

				
		    	

				<div style='display: table; position: absolute; bottom: 0; width: 100%;'>
				<button :disabled='!info.updateOptimizerSettings' :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveOptimizerSettings(optimParam)'>SAVE</button>
				<button class='button myButton' :style="getAppStyle()+'float: right;'" @click='loadPlacementInfo(placement)'><mdicon :width="16" name="reload" /></button>
				</div>
				     	
	        </div>
	        <!-- SHARES  -->
	        <div v-else-if="settingsLabels[settingsType]==='Shares'" class="transitionOptimizer settingsBlock">
				<div v-if="sharesInfo && sharesInfo.spots">
					
					<div style="float: left; padding-top: 3pt;"><b>Current share distribution</b></div>
					<div style="float: right;"><WindowCloseBN @click="sharesInfo=null;"/></div>
					
					<div style='width: 100%; overflow-y: auto; height: calc(25vH);'>
					<table width="100%">
						<tr><td class="smallText2bx">Share</td>
						<td class="smallText2rx">Limit</td>
						<td class="smallText2rx">Spots</td>
						<td class="smallText2rx">Spots%</td>
						<td class="smallText2rx">Budget</td>
						<td class="smallText2rx">Budget%</td>
						</tr>
						<tr>
						<td class="smallText2x">total</td>
						<td class="smallText2"></td>
						<td class="smallText2">{{sharesInfo.spots}}</td>
						<td class="smallText2"></td>
						<td class="smallText2">{{sharesInfo.budget}}</td>
						<td class="smallText2"></td>
						</tr> 
						<tr v-for="(d,di) in sharesInfo.details" :key="'shd_'+di">
							
							<td v-if="d.share.tree" :title="d.share.name" class="smallText2x">{{ d.share.name }}</td>
							<td v-else :title="d.share.name" class="smallText2x" style="color:#aaa;">{{ d.share.name }}</td>
							<td class="smallText2">{{ d.share.limitOriginal }}</td>
							<td class="smallText2">{{ d.spots }}</td>
							<td class="smallText2">{{ Math.round(1000*d.spots/sharesInfo.spots)/10 }}%</td>
							<td class="smallText2">{{ d.budget }}</td>
							<td class="smallText2">{{ Math.round(1000*d.budget/sharesInfo.budget)/10 }}%</td>
						</tr>
					</table>
					</div>
					<div style='display: table; position: absolute; bottom: 0; width: 100%;'>
						<button :style="getAppStyle()+'float: right;'" title="back to share definition" class='button myButton' @click='sharesInfo=null'><mdicon :width="16" name="backspace" /></button>
					
						<button :style="getAppStyle()+'float: right;'" title="back to share definition" class='button myButton' @click='checkShares()'><mdicon :width="16" name="reload" /></button>
						
					</div>
				</div>
				<div v-else>
	            <Shares v-model="info.shares" :shareTypes="info.shareTypes" 
	                    @removeShare="removeShare" @copyShare="copyShare" 
	                    @openInvEditor="openInvEditorShare"></Shares>
	            
	            <div style='display: table; position: absolute; bottom: 0; width: 100%;'>
				<button :disabled='!info.updateShares' :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveShares(info.shares)'>SAVE</button>
				<button :style="getAppStyle()+'float: right;'" class='button myButton' @click='loadPlacementInfo(placement)'><mdicon :width="16" name="reload" /></button>
				<button :style="getAppStyle()+'float: right;'" class='button myButton' title="check shares against bookings" @click='checkShares()'><mdicon :width="16" name="list-status" /></button>
				<button title="create share" :style="getAppStyle()+'float: right;'" class='button myButton' @click='createShare(placement)'><mdicon :width="24" name="share-circle" /></button>
				
				</div>
				</div>
				
	        </div>
			<div v-else-if="settingsLabels[settingsType]==='channels'" class="transitionOptimizer settingsBlock">
			 	<FeedLimits :placementId="placement.id" v-model="info.feedLimits" :actualsIn="info.feedLimitsActuals" :targetType="info.targetType"
				            @changed="updateFeedLimits" @target="p=>updateMainTarget(p)"
							:mainTarget="info.placement.target" :budget="placement.budget"/>
							
		     </div>
	        <!-- WEEKLIES  -->
	        <div v-else-if="settingsLabels[settingsType]==='Weeks'" class="transitionOptimizer settingsBlock">
				<WeekLimits :placementId="placement.id" v-model="info.weekLimits" @changed="updateWeekLimits" :budget="placement.budget"/>
				
	        </div>
			<!-- WEEKLIES (2)  -->
	        <div v-else-if="settingsLabels[settingsType]==='WeekShares'" class="transitionOptimizer settingsBlock">

				
				<WeekAndTimeLimits :placementId="placement.id" v-model="info.weekShareLimits" :timeFrom="info.timeFrom" :timeTo="info.timeFrom"
				                   :weeklies="info.weekLimits" @weeklies="setWeeklies" 
								   :sharesInp="info.shares" :budget="placement.budget"/>
				
	        </div>
	        <!-- CAPPINGS  -->
	        <div v-else-if="settingsLabels[settingsType]==='Capping'" class="transitionOptimizer settingsBlock">
	            <b>Cappings/Limits</b>
	            <div style='padding-top: 10pt;'></div>
	            <Cappings :cappingUnits=info.cappingUnits />
	            
	            <div style='display: table; position: absolute; bottom: 0; width: 100%;'>
				<button :disabled='!info.updateCappings' :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveCappings(info.cappingUnits)'>SAVE</button>
				<button :style="getAppStyle()+'float: right;'" class='button myButton' @click='loadPlacementInfo(placement)'><mdicon :width="16" name="reload" /></button>
				
				</div>
				
	        </div>
	        <!-- CLASHES  -->
	        <div v-else-if="settingsLabels[settingsType]==='Clash'" class="transitionOptimizer settingsBlock">
				<div style='width: 100%; overflow-y: auto; height: calc(25vH);'>
		            <b>Potential clashes</b>
		            <div style="margin-bottom: 12pt;"></div>
		            <div v-for="(clash, clashi) in info.clashes" :key="'cl'+clashi" style="font-size: 9pt; height: 20pt;">
		            {{clash.name}}, {{clash.levelValue}}-Level, {{clash.limitOfSpots}} Spots
		            <button class='myButtonLight button' style='float: right; margin: 0pt; height:18pt;' @click="$refs.editor.open('Clashes', clash.id)"><mdicon  :width="16" name="pencil" /></button>
		            </div>
					
					<b>Potential exclusions</b>
		            <div style="margin-bottom: 12pt;"></div>
		            <div v-for="(excl, excli) in info.exclusions" :key="'excl'+excli" :title="excl.inventory.title" style="font-size: 9pt; height: 20pt;">
		            {{excl.name}} ({{excl.whitelist?"WHITELIST":"BLACKLIST"}})
		            <button class='myButtonLight button' style='float: right; margin: 0pt; height:18pt;' @click="$refs.editor.open('Exclusions', excl.id)"><mdicon  :width="16" name="pencil" /></button>
		            </div>

	            </div>
	            <div style='display: table; position: absolute; bottom: 0; width: 100%;'>
				<button :style="getAppStyle()+'float: right;'" class='button myButton' @click='loadPlacementInfo(placement)'><mdicon :width="16" name="reload" /></button>
				
				</div>
				
	        </div>
	        <div v-else-if="settingsLabels[settingsType]==='Filter'" class="transitionOptimizer settingsBlock">
	            <div style='padding: 5pt; font-size: 8pt; '>
	            Display all involved filters. Changes are available in the traffic manager but not saved/stored unless "save" button is pressed
	            </div>
	            
	            
				<span class='smallTextFull bold'>{{tr('Placement')}}:</span>
	            <InventoryFilterEditor  
		                 	  	v-model="filterStructure.placementFilter"
		                 	  	:columnName="'placementFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	:hideBorder="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		                 	  
		       <span class='smallTextFull bold'>{{tr('Client')}} {{filterStructure.client.name}}:</span>          	  
		       <InventoryFilterEditor  
		                 	  	v-model="filterStructure.clientFilter"
		                 	  	:columnName="'clientFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	:hideBorder="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		         <div v-if="filterStructure.product">
		       	 <span  class='smallTextFull bold'>{{tr('Product')}} {{filterStructure.product.name}}:</span>          	  
		         <InventoryFilterEditor  
		                 	  	v-model="filterStructure.productFilter"
		                 	  	:columnName="'productFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	:hideBorder="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		          </div>
		       <div v-if='hasFilter( filterStructure.copyFilter)'>
		        <span class='smallTextFull bold'>{{tr('Copy')}}:</span>         	  
		        <InventoryFilterEditor  
		                 	  	v-model="filterStructure.copyFilter"
		                 	  	:columnName="'copyFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		        </div>
		       
	            <div style='display: table; position: absolute; bottom: 0; width: 100%;'>
	            <button :disabled='!filterStructure.updateFilters' :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveAllFilters(filterStructure)'>SAVE</button>
	            <button :style="getAppStyle()+'float: right;'" class='button myButton' @click='getAllFilters(placement)'><mdicon :width="16" name="reload" /></button>
				
				</div>

	        </div>
		    <div v-else-if="settingsLabels[settingsType]==='General'" class="transitionOptimizer settingsBlock">
		     	
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
	            <span class='smallTextSlider bold'>Background-color for grp values</span>
	            <div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
	                
	               
					<button @click="showColorPicker($event, 'grpColor')" class='button smallButton' 
					        :style="'color: '+autoFG(options.grpColor)+';background-color: '+options.grpColor">GRP</button>
					       
				</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
					<span class='dontWrap smallText bold'>maximal GRP:</span>
				    <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
					<vue-slider :marks="false" min="1" max="30" v-model="maxGRP"></vue-slider>	
					</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
	            <span class='smallTextSlider bold'>Color for unplaced spots</span>
	            <div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
					<button @click="showColorPicker($event, 'unplacedColor')" 
					        class='button smallButton' :style="'color: '+autoFG(options.unplacedColor)+';background-color: '+options.unplacedColor">Unplaced</button>

				</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
		            <span class='smallTextSlider bold'>Color for free spots</span>
		            <div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
						<button @click="showColorPicker($event, 'freeColor')" 
						        class='button smallButton' :style="'color: '+autoFG(options.freeColor)+';background-color: '+options.freeColor">Free</button>

					</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
		            <span class='smallTextSlider bold'>Color for not available slots/breaks</span>
		            <div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
						<button @click="showColorPicker($event, 'unavailColor')" 
						        class='button smallButton' :style="'color: '+autoFG(options.unavailColor)+';background-color: '+options.unavailColor">not avail.</button>

					</div>
				</div>
			
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
				<span class='smallText bold' style='width: 50%;'>Show unplaced in grid</span>
				<div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
					<app-switch v-model="options.showUnplaced" :checked="options.showUnplaced"/>
				</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
					<span class='smallText bold' style='width: 50%;'>Show free-spots in grid</span>
					<div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
						<app-switch v-model="options.showFrees" :checked="options.showFrees"/>
					</div>
				</div>
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
					<span class='smallText bold' style='width: 50%;'>Compact grid view</span>
					<div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
						<app-switch v-model="options.compactView" :checked="options.compactView" />
					</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
					<span v-if="options.gridViewFin" class='smallText bold' style='width: 50%;'>Financial day</span>
					<span v-else class='smallText bold' style='width: 50%;'>TX day</span>
					<div class="dontWrapIB" style='float: right; padding-top: 3pt; width: 40%; display: inline-block;'>
						<app-switch v-model="options.gridViewFin" :checked="options.gridViewFin"/>
					</div>
				</div>
				
				<div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
				<span class='dontWrap smallText bold'>Font-Size:</span>
			    <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 40%; display: inline-block;'>
				<vue-slider :marks="false" min="5" max="16" v-model="options.fontSize"></vue-slider>	
				</div>
				</div>
				
				
				
			
				
				<div style='display: table; position: absolute; bottom: 0; width: 100%;'>
				<button :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveUserBookSettings(user)'>SAVE</button>
				</div>
				
		    </div>
	    	
	    	
	    	
	    	
	    	<button :disabled="info.placementType.filler" @click='showAdBreaksNew(placement)' :style="getAppStyle()" title='Open booking-grid/calendar' class='button myButton'><mdicon size="16" name="calendar-blank"/> Grid-View</button>
			<!--	
			<button @click='getAdBreaks(placement)' :style="getAppStyle()" title='Open booking-grid/calendar' class='button myButton'><mdicon size="16" name="calendar-blank"/> OLD</button>				    	
			<button @click='getAdBreaks(placement, true)' :style="getAppStyle()" title='Show day grid' class='button myButton'><mdicon size="16" name="clock-outline"/></button>
			-->
	    	<button @click='showSpots(placement)' title='show list of booked spots' :style="getAppStyle()" class='button myButton'><mdicon size="16" name='format-list-bulleted-square'/> Spot-List</button>
	    	<button v-if="info.placementType.optimizableValue==='OFF' || !duration || duration==''" disabled="true" :style="getAppStyle()" title="Placement-Type doesn't allow optimisation" class='button myButton'><mdicon :width="20" name="cart-off" /> Optimiser</button>
			<button v-else :style="getAppStyle()" @click='optimize( placement)' title='Book placement with the optimiser' class='button myButton'><mdicon :width="20" name="cart-arrow-down" /> Optimiser</button>
			<button v-if="placement.positionInBreakId && placement.positionPercentage && placement.positionPercentage < 100" 
			        :style="getAppStyle()" @click='runApplyPositionRequests( [])' 
					:title="'Apply position requests (Target: '+placement.positionPercentage+'%)'" class='button myButton'><mdicon :width="20" name="sort-numeric-ascending" /></button>
						
				    	
	    	<button v-if="placement" :style='getAppStyle()+"height:32px; float: right;"' 
	    	        title='reload all data' class='button myButton' @click="setPlacement( placement, showPlacementGrid);"><mdicon size="16" name='reload'/></button>
		    	
	    	
			<!--
	    	<button @click='report(placement)' :style="getAppStyle()" class='button myButtonVSmall'><mdicon :width="14" name="file-outline" /></button>
			-->
			<div class="dontWrap" style="width: 100%; overflow-x: auto;">
			<button @click='downloadConf(placement)' :style="'left: 0; z-index: 9; position: sticky; '+getAppStyle()" class='button myButtonSmall'><mdicon :width="14" name="download" /></button>
	    	
	    	<div style="display: inline-flex" v-for="(r,ri) in info.reports" :key="'r'+ri">
				<button @click='downloadSpotList(placement.id, r)' :title="r.name" :style="getAppStyle()+'font-size:8pt;'" class='button myButtonSmall'>{{r.shortname}}</button>
			</div>
			</div>
		</div>
		</div>
     </div>
     <div v-else-if='campaign.id' class='selection' 
          style='float: left; display: inline-block; border-radius: 6px; box-shadow: 3pt 4pt 6pt 0 #aaa; padding-left: 0pt; width: 99% !important; margin-top: 2pt;'>
       <!-- CONTROLS PLACEMENT/INFO -->
           <div class='BMappHead' >
              <div title='open placement' class="colored" style='height: 24pt !important;' @click="openEntity($event, 'Campaign', campaign.id)">
              <div style='float: left; display: inline-flex; font-size: 9pt;'>
              <span class='dontWrap90 smallText' style='width: 90%; text-align: left;' >{{campaign.name}}</span>
             </div>
              <button title='close view'  id='closePopup2' type="button" class="iButton" @click='closeDetails($event)' >
					<mdicon :width="16" name="close"/>
              </button>
              </div>
             </div> <!-- appHead -->
     
     
    	<div style='padding-left: 5pt; padding-right: 5pt; width: 100%; margin-top: 2pt;'>
    	
    
        <div class="BMSection">
              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER</b></span>
        </div>

		<div style='width: 100%'>
    	<span class='dontWrap smallText bold'>Date range:</span>     
    	</div>
    	<div style='float: left; width: calc(45%);'>
    	<InputDatePick :auto='false' v-model="fromDate"/> 
    	</div>

    	<div style='float: right; width: calc(45%);'>
    	<InputDatePick :auto='false' v-model="untilDate"/>
    	</div> 

    	<div style='width: 100%'>
    	<span class='dontWrap smallText bold'>Weekdays:</span> <InputWeekdays v-model="currentWeekdays" fontsize='10pt' width='100%'/>    
    	</div>
    	<span class='dontWrap smallText bold'>Breaktypes:</span>
    	<GSelect :options="breakTypeData" v-model="plcmBreakTypes" :multiple="true" @input="setBreakTypes">  
    	   <template #option="{ id, label }">
      					<span z-index="999">{{label}}</span>
    				 </template>
        </GSelect>
        
        <div class="BMSection">
              <span class='BMSectionDetail dontWrap90 smallText'><b>SETTINGS</b></span>
        </div>
        
        <span class='dontWrap smallText bold'>{{tr("Product")}}:</span>
        <GSelect :options="productData" v-model="product" :multiple="false" @input="setProduct">  
    	   <template #option="{ id, label }">
      					<span z-index="999">{{label}}</span>
    				 </template>
        </GSelect>
        <span class='dontWrap'>
        <span class='smallText bold smallDistance'>Duration: </span>
           <InputField v-model="duration"/>
        </span>
        <span class='dontWrap smallText bold'>{{tr("Copy")}}:</span>
        <GSelect :options="copyData"  v-model="copy" :multiple="false" @input="setCopy">  

        </GSelect>
        
        
        <span v-if="rotation && rotation.id" class='dontWrap smallText bold' @click="$refs.editor.open('Rotation', rotation.id)">{{tr("Rotation")}}:
        	
        </span>
        <span v-else class='dontWrap smallText bold'>{{tr("Rotation")}}:</span>
        <GSelect :options="rotationData" style='font-size: 9pt;' v-model="rotation" :multiple="false" @input="setRotation">  
    	   <template #option="{ id, label }">
      					<span z-index="999">{{label}}</span>
    				 </template>
        </GSelect>

    	</div>
    	<br/>
    	<div style='float: left; padding-left: 5pt; width: 100%; margin-top: 2pt;'>
    	<button @click='showSpots(placement,campaign)' class='button myButton'>Spot-List</button>
    	&nbsp;&nbsp;&nbsp;
    	<button @click='report(placement, campaign)' class='button myButton'><mdicon :width="16" name="file-outline" /></button>
		</div>
      </div>
     </div>




	<div v-if='showPlacementGrid'>

		<BookingGridNew :placementInfo=info
				     :inventoryPlacementResult=inventoryPlacementResult
					 :targetType=info.targetType
				     :targetGroup=targetGroup
					 :channels=info.channels
					 :selectedChannel=selectedChannel
				     :markBreaks=markBreaks
				     :consumedGrid=consumedGrid
				     :consumedBreak=consumedBreak
				     :spotsBreakTotal=spotsBreakTotal
				     :invalidSpots=displayInvalidSpots
				     :invalidSpotsChanged=invalidSpotsChanged
				     :user=user
					 :maxGRP="maxGRP/10"
					 :duration=durationTotal(duration)
				     :options=options
				     :dates=dates
				     :fromDate=fromDate
				     :untilDate=untilDate
				     :allBookingStats=allBookingStats
				     :inputBreakTypeIds=plcmBreakTypeIds
				     :inputWeekdays=currentWeekdays
				     :time="countPlacementGrid"
					 @markedSpots="p=>markedSpots=p"
					 @selectedChannel="p=>selectedChannel=p"
					 @markedGRP="p=>markedGRP=p"
					 @markedBudget="p=>markedBudget=p"
				     @saveUser=saveUser
				     @bookMarked="bookMarked"
				     @bookMove="bookMove"
				     @deleteSpot="deleteSpot"
				     @moveSingleSpot="moveSingleSpot"
				     @cancelMarked="cancelMarked" 
				     >
		</BookingGridNew>
		
		
    </div>
	
    
    <div v-else-if="selectedGrid.id"  style='padding: 10pt 10pt 10pt 10pt; float: right; width: 75%;'>
    	<ul>
    	<div v-for="plcm in placements" :key='plcm.id'>
    		<li><a @click="openEntity(event, 'Placement', plcm.id)">{{plcm.name}}</a></li>
    	</div>
    	</ul>
    </div>
    <div v-else-if="showPlacementSpots" view="spotListTime" style="display: block; width: 100%;">
        <div style="display: block; float: top; width: 100%; height: 100%; " view="spotListTime">
    	<SpotList :placement=placement 
				  :placementInfo=info
    	          :placementIDs=placementIDs 
    	          :campaign=campaign
    	          :time="spotListTime" 
    	          :spotSearchFilter="spotSearchFilter"
    	          :inputBreakTypes=plcmBreakTypes
    	          :inputWeekdays=currentWeekdays
    	          :inputDateFrom=fromDate
    	          :inputDateTo=untilDate
    	          :copyRotation=rotation
    	          :deletedSpots=deletedSpots
    	          :updatedSpots=updatedSpots
    	          :options=options
    	          :preSelectMedia=preSelectMedia
				  @selectCopy="p=>{setCopy( copyData.find(c=>c.id==p))}"
    	          @placementId=setSinglePlacement
    	          @setPrice=setPrice
				  @setPositionReq=setPositionReq
				  @justTouch=justTouch
				  @switchTo=switchTo
    	          @saveUser=saveUser
    	          @removeCopy=removeCopy
    	          @removeRotation=removeRotation
    	          @deleteSpot="deleteSpot"
    	          @setTypeForSpots='setTypeForSpots'
    	          @runCopyRotation='runCopyRotation'
    	          @rebookSpots='doRebookSpots'></SpotList>
    	</div>
    </div>
    <GConfirm ref='confirm'/>
    <GFWEditorRouter v-show='showEditor' @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :dataId="dataId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    @update=updateEntity
    :dataRecord="selectedRecord">
    </GFWEditorRouter>
    
    <PDFViewer name='pdfView' ref='pdfView' />
	<BriefingInput ref='briefingView' @openPlacement="openPLCM" @reloadBriefing="reloadBriefing">
	</BriefingInput>
    
    <GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
    <ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
    <GFWEditorInventory :ref='getInvEditorId()' ></GFWEditorInventory>
    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'set color for '+optionColor}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
       name="modal-selectColor"  @on-close="closeColorPicker">
		<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
        <color-panel v-model="options[optionColor]" ></color-panel>
		</div>
    </vue-modal-2>
 </div>
</template>

<script>
import GFWTreeView from '@/components/GFWTreeView';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import InputTime from '@/components/inputElements/InputTime2';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
import PDFViewer from '@/components/PDFViewer';
import BriefingInput from '@/components/BriefingInput';
//import BookingGrid from '@/components/BookingGrid';
import BookingGridNew from '@/components/BookingGridNew';
//import BookingDayGrid from '@/components/BookingDayGrid';
import SpotList from '@/components/SpotList';
import ProgressBar from '@/components/ProgressBar';
import GSelect from '@/components/misc/GSelect'
import InputField from '@/components/booking/InputField'
import Shares from '@/components/booking/Shares'
import WeekLimits from '@/components/booking/WeekLimits'
import FeedLimits from '@/components/booking/FeedLimits'
import WeekAndTimeLimits from '@/components/booking/WeekAndTimeLimits'
import Cappings from '@/components/booking/Cappings'
import GFWEditorInventory from '@/components/GFWEditorInventoryInline';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import Switch from '@/components/Switch';
import GConfirm from '@/components/GConfirm';
import {fmtTimePart, printTimeHHMM, printTime2 } from '@/basicTimeFN.js';
import GProgress from '@/components/misc/GProgress';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBGStyle3, autoFG } from '@/AppStyle.js';
import {HTTP, HTTPMP, placementServiceAPI, fwAPI, userAPI, invAPI, bngAPI, opsAPI, reportAPI, inventoryInlineAPI, setReload, formatNumber,showError} from '@/variables.js';
import {getData,getPlacements,getDataByName} from '@/utils.js';
import { tr } from '@/translate.js';
import { goBack } from '@/breadCrumb.js';
import { formatPercent, format, format2 } from '@/Numbers.js';
import {store, restore, getRawKey, getRawKeyById } from '@/bookMan.js';
import JQuery from "jquery";
var momentTZ = require('moment-timezone');
var numeral = require('numeral');
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
let $ = JQuery;
var websocket;
var keepLockTimer = [];
export default {
  name: 'GFW BOOKMAIN',
  components : {
	  //vSelect, 
	  'app-switch': Switch, GFWEditorInventory,InputField,
     //GFWTreeView,  
     Cappings, Shares, WeekLimits,BriefingInput,
	 WeekAndTimeLimits, GProgress, FeedLimits,
     InventoryFilterEditor
	 //, BookingDayGrid, // InputFieldS,
     ,WindowCloseBN, GConfirm, BookingGridNew,
    InputDatePick, InputWeekdays, GSelect, SpotList, ProgressBar, PDFViewer
  },
  data () {
    return {
		
	  
      placement: {},
      placementIDs: [],
      filterStructure: {},
      spotSearchFilter: {},
      campaign: {},
	 
	  
      tr: tr,
      printTime2,
      formatPercent,
      format,
      openThisModule: "",
      placements: null,
      inventory: {},
      gridValid: [],
      grid: [],
      dayGrid: [],
      gridData: [],
      mediaSet: [],
      channels: {},
      sharesInfo: {},
      preSelectMedia: null,
      allTargetGroups: [],
      allBookStatus: [],
      selectedBookStatus: {},
      targetGroup: {},
      timeSlots: {},
      productData: [],
      product: {},
      copy: {},
      businessType: {},
      setDate: '',
	  selectedChannel: {},
      duration: "20",
      allCopyData: [],
      copyData: [],
      rotation: {},
      deletedSpots: [],
      updatedSpots: [],
      allRotationData: [],
      rotationData: [],
	  uploadData: {},
	  bookBriefingsSummary: null,
	  briefingOpt : {
		time: 2000,
		weekTolerance: 2,
		addPaid: 5,
		addFree: 100,
		printInput: false
	  },
	  formData: {},
	  formDataName: "",
	  update: 0,
      
      durationData: [],
      breakTypeData: [],
      businessTypes: [],
      plcmBreakTypes: [],
      plcmBreakTypeIds: [],
      showEditor: false,
      entityMap: {},
      spotListTime: 0,
      mapping: [],
      metadata: {},
      dataId: 0,
      fromDate: {},
      untilDate: {},
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      widths: [],
      currentWeekdays: 127,
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],
      dates: [],
      markBreaks: [],
      isoDates: [],
      spotsDay: [],
      spotsGrid: [],
      spotsUnplacedBreak: [],
      consumedGrid: [],
      consumedBreak: [],
      spotsBreakTotal: [],
      allBookingStats: [],
      invalidSpots: [],
      displayInvalidSpots: [],
      invalidSpotsChanged: 0,
      spotsBreak: [],
      spotsPlacement: 0,
      spotsPlacementTotal: 0,
      spotsPlacementTotalFree: 0,
	  inventoryPlacementResult: {},
      info: {},
      grpPlacementTotal: 0,
      budgetPlacementTotal: 0,
      budgetPlacementFreeTotal: 0,
      budgetPlacementPaidTotal: 0,
      rateCardPlacementTotal: 0,
      spots: 0,
	  markedSpots: 0,
	  markedGRP: 0,
	  markedBudget: 0,
	  maxGRP: 2,
      shown: false,
      selectedRef: "",
      selectedGrid: {},
      treeHeight: 90,
      countPlacementGrid: 0,
      showPlacementGrid: 0,
	  showPlacementGridOld: 0,
      showPlacementSpots: 0,
      user: {},
      
      showProgressBar: false,
      pbDayGrid: false,
	  pbBookType: "",
      pbAction: "",
      pbTitle: "Performing booking action",
      
      idList: [],
      bookReq: {},
      getAppStyle, getBGStyle3, getBGStyle2, autoFG, setDarkMode, format2,
      settingsLabels: [ "Book","Opt","upl","Filter","channels", "Weeks","Shares","WeekShares","Capping","Clash","General"],
      settingsIcons: [ "", "", "upload", "filter", "television", "calendar-week", "share",  "speedometer", "numeric", "fencing", "cog" ],
	  settingsTooltip: [ 
	  "Setup for manual bookings/adjustments",
	  "Optimizer-Setup/Settings",  
	  "Upload briefings/booking-import",  
	  "Filter-setup", 
	  "Channel targets with core timeslots",
	  "Weekly targets",
	  "Flexible shares (timeslots/weekdays...)", 
	  "Week/Time/Shares",
	  "Limits/Cappings", 
	  "Possible product-clashes and program exclusions", 
	  "General setup/settings"
	  ],
      settingsIconsOff: [ "", "", "upload", "filter-off", "television-off", "calendar-week", "share-off",  "speedometer-off", "numeric-off", "fencing","cog" ],
      settingsType: 0,
      optionColor: "grpColor",
      myId: Math.round(Math.random()*100000000),
      options: {
    	  grpColor: '#f2d9b3',
    	  unplacedColor: '#44f',
		  unavailColor: '#eee',
		  freeColor: '#2b2',
		  fillerColor: '#000',
		  fixedColor: '#000',
    	  showFrees: true,
		  compactView: false,
		  showUnplaced: true,
		  gridViewFin: null,
    	  fontSize: 11
      },
      optimParam: {
      		fixBookings: false,
      		timeUnimpr: 2500,
      		timeOptMax: 40000,
      		loading: 100,
      		safety: 0,
      		performBookings: true
           }
    }
  },
  beforeUpdate: function () {
  	
  },

  methods: {
	openUpload( evt)
	{
		
		if ( evt && evt.dataTransfer && evt.dataTransfer.types && evt.dataTransfer.types[0] === 'Files' )
		{
			this.settingsType = 2;
		}
	},
	filterLabels( labels)
	{
		if (this.placement.media && this.placement.media.length > 1)
		{
			return labels.filter( p=>p !== 'channels')
		}
		return labels;
	},
	  getInvEditorId() {
	    	return 'INVEDIT'+this.myID; 
	    },
	    getInvEditor() {
	    	if ( Array.isArray(this.$refs[this.getInvEditorId()]))
			{
				return this.$refs[this.getInvEditorId()][0];
			}
			else
			{
				return this.$refs[this.getInvEditorId()];
			}
	    },
		hoverLine(line)
	    {
	    	$('#'+line).addClass('reqLineBG2');
	    },
	    hoverLineMain(line)
	    {
	    	//$('#'+line).addClass('reqLineBG');
	    },
	    leaveLine(line)
	    {
	    	$('#'+line).removeClass('reqLineBG2');
	    },
		dropFile($event, lineREF)
		{
	      let that = this;
	      event.preventDefault();
	      event.stopPropagation();
	      this.showDropzone = false;
	      const files = event.dataTransfer.files;
	      const itemArray = [...files];
	      
	      const item = itemArray.find((i) => true);
		  let name = item.name;
		  if ( !name.endsWith(".xlsx"))
		  {
			that.$toast.error("Wrong file-type, only .xlsx provided", 'Error', { position: "topRight" });
			return;
		  }
		  if ( name.length > 20)
		  {
			name = name.substring(0,17)+"...";
		  }
	      this.$refs.confirm.confirm( {text: 'Load Briefing: ' + name, title: "Upload Bookings", button1: "Cancel", button2: "Ok"}).then(x => {
	    	 
	    	  let check =  new Promise(function (resolve, reject) {
	    		  const formData = new FormData();
		       	  
		       	  that.leaveLine(lineREF);
			      formData.append('file', item);      
			      if (item) that.upload(formData, that.placement, item.name);
			      //that.stopLoader();
	
			   });
	      });
	    },
		reloadBriefing()
		{
			this.uploadData=null; 
			this.upload(this.formData, this.placement, this.formDataName, true);
			
		},
		async upload(data, placement, name, reloadBriefing) {
		     let uploadFile = await this.uploadFile(data, placement, name, reloadBriefing);
		   },
	   uploadFile(formData, placement, name, reloadBriefing) 
       {
         let that=this;
		
         this.formData = formData;
		 this.formDataName = name;
         
         HTTPMP.put( placementServiceAPI+"/importBookingFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+placement.id, formData)
           .then( response => 
           { 
			 that.uploadData = response.data;
			 that.fromDate = new Date( that.uploadData.from);
			 that.untilDate = new Date( that.uploadData.to);
			 that.bookBriefingsSummary = null;
			 if ( reloadBriefing )
			 {
				that.$refs.briefingView.open(that.uploadData, placement, that.briefingOpt);
			 }
			}).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); that.stopLoader();})
	
		},
	  updateWeekLimits( weeklies)
	  {
		 this.info.weekLimits= weeklies;
		 this.info = {...this.info}
	  },
	  updateFeedLimits( feedLimits)
  	  {
  		 this.info.feedLimits= feedLimits;
		 //alert( JSON.stringify( this.info.feedLimits.limits))
  		 this.info = {...this.info}
  	  },
	  setWeeklies( w)
	  {
			this.info.weekLimits = w;
	  },
	  closeScreen()
      {
          this.$emit('clearAll');
          let x = goBack();
          if ( x.path.startsWith('/fw/') )
          {
          	this.$router.replace( '/fw/myDashBoard');
          }
          else
          {
      		this.$router.replace( x.path);
      	}
      },
	  copyShare( share)
	  {
		let copy = {...share};
		copy.id = 0;
		this.info.shares.push( copy);  
	  },
	  createShare( placement )
	  {
		let copy = {id: 0, placementId: placement.id, tenantId: placement.tenantId, name: "new share", type: 0, tolerance: 5};
		this.info.shares.push( copy);  
	  },
	  removeShare( share)
	  {
		this.info.shares = this.info.shares.filter(p=>p!==share);  
	  },
	  openInvEditor( filter, record, columnName, forceOpen)
      {
      	//alert("GFWE"+JSON.stringify(record)+columnName)
      	this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventory);
      },
      openInvEditorShare( filter, record, columnName, forceOpen)
      {
      	//alert("GFWE"+JSON.stringify(record)+columnName)
      	this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventoryShare);
      },
      setInventory( filter)
      {
		  this.filterStructure=filter;
      },
      setInventoryShare( filter, record, column)
      {
		  record[column] = filter[column];
      },
    getOpenThisModule() 
    {
    	this.openThisModule = this.$route.query.toOpen;
    	//alert( "openThisModule = "+this.openThisModule);
    	return this.openThisModule;
    },
    hasFilter( f)
    {
    	return f && (f.children || f.atomicLines)
    },
    fmt( val)
    {
       return formatNumber( val );
    },
    showColorPicker( event, color)
    {
    	this.optionColor = color;
        this.$vm2.open('modal-selectColor');
    	
    },
    closeColorPicker()
    {
    	this.$vm2.close('modal-selectColor');
    },
   
    getPercent( spots, targetType, target)
    {
    	return target===0? 100: 100*spots/target;
    }, 
    closeDetails(event) {
       event.stopPropagation();
       this.treeHeight=90.0;
       this.placements = [];
       this.gridValid = [];
       this.inventory={};
       this.placement={};
       this.placementIDs = [];
       this.selectedGrid={}
       this.showPlacementGrid = false;
	   this.showPlacementGridOld = false;
       this.showPlacementSpots = false;
    },
    
    getWidth() 
    {
    	let max = window.innerWidth;
    	
    	let avail = (max * 0.70) - 210;

    	return avail;
    },
    getHeight() 
    {
    	let max = window.innerHeight;
    	
    	let avail = (max)-120;

    	return avail;
    },
    calcHeight()
    {
    	let mh = window.innerHeight;
    	return "height: " + (mh - (mh*this.treeHheight)*.01)+"px;"
    },
    showSpots(placement,campaign)
    {
		this.showPlacementGrid = false;
		this.showPlacementGridOld = false;
    	this.showPlacementSpots = true;
		let that = this;
		this.$nextTick(function () {
    		that.$set( that, "spotListTime", parseInt(that.spotListTime)+1);
		});
    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	getIcon( idx){
		let label = this.settingsLabels[ idx];
		if ( label === "Shares")
		{
			if ( this.info && this.info.shares && this.info.shares.length)
			{
				return { label: this.settingsIcons[ idx], color: "#000"};
			}
			return { label: this.settingsIcons[ idx], color: "#aaa"};
		}
		if ( label === "Capping")
		{
			if ( this.info && this.info.cappingUnits.map(p=>p.capping).find(p=>p))
			{
				return { label: this.settingsIcons[ idx], color: "#000"};
			}
			return { label: this.settingsIcons[ idx], color: "#aaa"};
		}
		if ( label == "Clash")
		{
			if ( this.info.clashes.length + this.info.exclusions.length )
			{
				return { label: this.settingsIcons[ idx], color: "#000"};
			}
			return { label: this.settingsIcons[ idx], color: "#aaa"};
		}
		if ( label === "WeekShares")
		{
			if ( this.info && this.info.weekShareLimits && this.info.weekShareLimits.limits && this.info.weekShareLimits.limits.length)
			{
				return { label: this.settingsIcons[ idx], color: "#000"};
			}
			return { label: this.settingsIcons[ idx], color: "#aaa"};
		}
		if ( label === "Filter")
		{
			try 
			{
				if ( this.filterStructure 
				    && (( this.filterStructure.placementFilter && this.filterStructure.placementFilter.title && this.filterStructure.placementFilter.title.indexOf('no restriction') === -1) ||
				        ( this.filterStructure.clientFilter && this.filterStructure.clientFilter.title && this.filterStructure.clientFilter.title.indexOf('no restriction') === -1) ||
				        ( this.filterStructure.productFilter && this.filterStructure.productFilter.title && this.filterStructure.productFilter.title.indexOf('no restriction') === -1) 
				       )
				   )
				{
					return { label: this.settingsIcons[ idx], color: "#000"};
				}
			} catch (e) {
				//
			}
			return { label: this.settingsIcons[ idx], color: "#aaa"};
		}
		
		return { label: this.settingsIcons[ idx], color: "#000"};
	},
    report(placement, campaign)
    {
    	
    	let that = this;
    	let campaignId = (campaign && campaign.id) ? campaign.id : placement.campaignId;
    	let placementId = (placement && placement.id) ? placement.id : 0;
		HTTP.post( reportAPI+"/getCampaignReport/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+campaignId+"/"+placementId)
            .then( response => {
            	let downloadResponse = response.data;
				that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
            }).catch(e => {
                    this.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
    	
    },
	bookMove(fromTo) {
		
		let that = this;
		let n = 1;
		var bookRequest = {"productId": this.product.id, 
                "duration": this.durationTotal(this.duration), 
                "copyId": this.copy.id,  
                "status": {...this.selectedBookStatus},
				"view": this.inventoryPlacementResult.view,
                "lines": fromTo };
		//alert( JSON.stringify( bookRequest))
		if ( this.duration.includes("+"))
		{
			delete bookRequest.duration;
		}
		//alert( JSON.stringify( bookRequest))
		delete bookRequest.status.label;
	    HTTP.put( bngAPI+"/bookMove/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id, bookRequest)
            .then( response => 
            { 
				if ( that.inventoryPlacementResult && that.inventoryPlacementResult.gridPeriodResult )
				{
					that.updateAdBreaksInternalNew();
				}
				else
				{
                	//console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
                	that.getPlacementSpots(that.placement);
               	 	that.getSpots();
        		} 
            }).catch(e => {
                    this.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
		
	},
	moveSingleSpot(fromTo) {
		
		let that = this;
		let time = new Date().getTime();
		let n = 1;
		//alert( JSON.stringify( fromTo));
	    HTTP.put( bngAPI+"/moveSingleSpot/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/move", fromTo)
            .then( response => 
            { 
                let dur = (new Date().getTime()-time);
                
                //console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
                that.getPlacementSpots(that.placement);
                that.getSpots();
        		 
            }).catch(e => {
                    this.$toast.error(e.response.data, 'Error', { position: "topRight" });

            });
		
	},
	justTouch( spots )
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"ids": ids };
		let that = this;
		let n = ids.length;
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//alert( JSON.stringify(bookRequest));
		
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/0", bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                
	                //console.log("done "+n+" rebooks in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
	                //that.$forceUpdate();
	                //that.getSpots();
	                //that.spotListTime++;       
	            }).catch(e => {
	                    this.$toast.error(e.response.data, 'Error', { position: "topRight" });
	
	            });
	},
	updateMainTarget(p)
	{
		let that = this;
		//alert(placementServiceAPI+"/setPlacementTarget/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id+","+ parseFloat(p))
		HTTP.put( placementServiceAPI+"/setPlacementTarget/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id, {target:parseFloat(p)})
        .then( response => 
        { 
			that.info.placement.target = response.data.placement.target;
			that.placement.target = response.data.placement.target;
        	that.$toast.success("Placement target saved", 'Ok', { timeout: 1000, position: "topRight" });
        }).catch(e => {
			console.log( e)
        	showError( that.$toast, "save placement target", e)
        });
	},
	setPositionReq( spots, posId )
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"positionReqId": posId, "ids": ids };
		let that = this;
		let n = ids.length;
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//alert( JSON.stringify(bookRequest));
		
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/0", bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                
	                //console.log("done "+n+" rebooks in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
	                //that.$forceUpdate();
	                //that.getSpots();
	                //that.spotListTime++;       
	            }).catch(e => {
	                    this.$toast.error(e.response.data, 'Error', { position: "topRight" });
	
	            });
	},
	setPrice(spots, price)
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"fixedPrice": price, "ids": ids };
		let that = this;
		let n = ids.length;
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//console.log( JSON.stringify(bookRequest));
		
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/0", bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                
	                //console.log("done "+n+" rebooks in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
	                //that.$forceUpdate();
	                //that.getSpots();
	                //that.spotListTime++;       
	            }).catch(e => {
	                    this.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
	switchTo( plcmStatus)
	{
		let that = this;
		this.$refs.confirm.confirm( {text: plcmStatus.name, title: "Switch to:", button1: "Cancel", button2: "Switch"}).then(x => {
			that.bookReq = plcmStatus;
	  	    that.pbAction = "switchToInternal";
	  	    that.pbTitle = "switching to "+plcmStatus.name ;
	  	    that.showProgressBar = true;
		});
	},
	switchToInternal(myId, plcmStatus, api)
	{
		let that = this;
		api.post( placementServiceAPI+"/switchTo/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+myId, plcmStatus)
        .then( response => 
        { 
			this.setPlacement( this.placement, this.showPlacementGrid);
			this.showProgressBar = false;     
        }).catch(e => {
			this.showProgressBar = false;
            that.$toast.error(e.response.data, 'Error', { position: "topRight" });
        });
	},		
	runCopyRotation( spots)
  	{
  		this.bookReq = spots;
  	    this.pbAction = "runCopyRotationInternal";
  	    this.pbTitle = "Performing copy-rotation";
  	    this.showProgressBar = true;
  	},
	runCopyRotationInternal(myId, spots, api)
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		let rotationId = this.rotation.id;
		
		var bookRequest = {"rotationId": rotationId, "ids": ids };
		let that = this;
		let n = ids.length;
		api.put( bngAPI+"/copyRotation/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+myId, bookRequest)
	            .then( response => 
	            { 
	                that.getPlacementSpots(that.placement);
	                that.getSpots();
	                that.spotListTime++;  
					this.showProgressBar = false;     
	            }).catch(e => {
					this.showProgressBar = false;
	                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });
	},
	runApplyPositionRequests( spots)
  	{
  		this.bookReq = spots;
  	    this.pbAction = "runApplyPositionRequestsInternal";
  	    this.pbTitle = "Performing position requests";
  	    this.showProgressBar = true;
  	},
	runApplyPositionRequestsInternal(myId, spots, api)
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		let rotationId = this.rotation.id;

		let that = this;
		let n = ids.length;
		api.put( bngAPI+"/applyPositionRequests/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+myId)
	            .then( response => 
	            { 
					that.$toast.success( formatNumber( "Target: " +response.data.target) +"%, Current: "+ formatNumber( response.data.current) + "%, Possible: "+formatNumber( response.data.possible)+"%", 
					                     'Ok', { timeout: 4000, position: "topRight" });
	                that.getPlacementSpots(that.placement);
	                that.getSpots();
	                that.spotListTime++;  
					this.showProgressBar = false;     
	            }).catch(e => {
					this.showProgressBar = false;
	                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });
	},
	doRebookSpots(spots)
	{
		let that = this;
		
		let ids = new Array();
		let changeInBusType = 0;
		for ( let i in spots )
		{
			ids.push( spots[i].id );
			if ( this.businessType && this.businessType.id != spots[i].businessTypeId)
			{
				changeInBusType++;
			}
		}
		if ( this.duration.split('+').length > 1 )
		{
			this.$toast.warning("Rebooking of spots only with single duration", 'Warning', { position: "topRight" });
			
			return;
		}
		if ( changeInBusType )
		{
			let msg = 'Businesstype change to '+this.businessType.label+' on '+changeInBusType+' spot(s)';
			let buttonArr = [];
			buttonArr.push( { text: 'Change businesstype', onClick: function(){
	            that.doRebookSpots2( spots, ids, true);
	        }});
			buttonArr.push( { text: 'Keep businesstype', onClick: function(){
				that.doRebookSpots2( spots, ids, false);
		    }});
	        buttonArr.push( { text: 'Cancel', onClick: function(){
						            
						          }});
	    	this.$iosAlertView({
		      title: msg,
		      
		      buttons: buttonArr
		    });
			
		}
		else
		{
			this.doRebookSpots2( spots, ids, false);
		}
	},
	forPaid( val)
	{
		return ( this.businessType.paid ? val : 0);
	},
	forFree( val)
	{
		return ( this.businessType.paid ? 0 : val);
	},
	doRebookSpots2(spots, ids, changeBT)
	{
		let that = this;
		let durInt = parseInt( this.duration);
		
		let changeInDuration = 0;
		for ( let i in spots )
		{
			ids.push( spots[i].id );
			if ( durInt && durInt != spots[i].duration)
			{
				changeInDuration++;
			}
		}
		if ( changeInDuration )
		{
			let msg = 'Duration change to '+durInt+' on '+changeInDuration+' spot(s)';
			let buttonArr = [];
			buttonArr.push( { text: 'Change duration', onClick: function(){
	            that.doRebookSpots3( spots, ids, changeBT, true);
	        }});
			buttonArr.push( { text: 'Keep duration', onClick: function(){
				that.doRebookSpots3( spots, ids, changeBT, false);
		    }});
	        buttonArr.push( { text: 'Cancel', onClick: function(){
						            
						          }});
	    	this.$iosAlertView({
		      title: msg,
		      
		      buttons: buttonArr
		    });
			
		}
		else
		{
			this.doRebookSpots3( spots, ids, changeBT, false);
		}
	},
	doRebookSpots3(spots, ids, changeBT, changeDur)
	{
		let that = this;
		if ( !this.copy.id )
		{
			this.doRebookSpots4( spots, ids, changeBT, changeDur, false);
			return;
		}
		let copy = this.copy.id;
		let changeInCopies = 0;
		for ( let i in spots )
		{
			ids.push( spots[i].id );
			if ( copy != spots[i].copyId)
			{
				changeInCopies++;
			}
		}
		if ( changeInCopies )
		{
			let txt = this.copy.label.length > 20 ? this.copy.label.substring(0,20) : this.copy.label;
			let msg = 'Copy change to '+txt+' on '+changeInCopies+' spot(s)';
			let buttonArr = [];
			buttonArr.push( { text: 'Change copy', onClick: function(){
	            that.doRebookSpots4( spots, ids, changeBT, changeDur, true);
	        }});
			buttonArr.push( { text: 'Keep copy', onClick: function(){
				that.doRebookSpots4( spots, ids, changeBT, changeDur, false);
		    }});
	        buttonArr.push( { text: 'Cancel', onClick: function(){
						            
						          }});
	    	this.$iosAlertView({
		      title: msg,
		      
		      buttons: buttonArr
		    });
			
		}
		else
		{
			this.doRebookSpots4( spots, ids, changeBT, changeDur, false);
		}
	},
	doRebookSpots4(spots, ids, changeBT, changeDur, changeCopy)
	{
		let that = this;
		if ( this.placement.price > 0 && !this.info.priceType.grp) 
		{
			let msg = 'Fixprice set to '+this.placement.price+' on '+spots.length+' spot(s)';
			this.$refs.confirm.confirm( {text: msg, title: "Rebook", button1: "Cancel", button2: "Rebook"}).then(x => {
				
    			that.performRebook( ids, changeBT, changeDur, changeCopy);
			});
		}
		else
		{
			that.performRebook( ids, changeBT, changeDur, changeCopy);
		}
	},				
	performRebook( ids, changeBT, changeDur, changeCopy )
	{
		let that = this;
		//alert( JSON.stringify(this.rotation));
		let rotationId = this.rotation ? this.rotation.id: 0;
		let bookRequest = {"productId": this.product ? this.product.id: 0, 
		                   "duration": this.duration, 
		                   "copyId": this.copy ? this.copy.id: null, 
		                   "rotationId": rotationId, 
		                   "statusId": this.selectedBookStatus.id,
		                   "ids": ids };

		if ( this.placement.price > 0 && !this.info.priceType.grp )
		{
			bookRequest["fixedPrice"] = this.placement.price;
		}
		let n = ids.length;
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//alert( JSON.stringify(bookRequest));
		let businessTypeId = (!this.businessType || !this.businessType.id)? 0:this.businessType.id;
		if ( !changeBT)
		{
			businessTypeId = 0;
		}
		if ( !changeDur)
		{
			delete bookRequest.duration;
		}
		if ( !changeCopy)
		{
			delete bookRequest.copyId;
		}
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+businessTypeId, bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                //console.log("done "+n+" rebooks in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
	                //that.getSpots();
	                //that.spotListTime++;       
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
	openPLCM( p )
	{
		alert("openPLCM")
		this.setPlacement( p, true);
		
	},
	removeRotation(spots)
	{
		//alert( "REMROT( " + spots.length+")");
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		if ( this.duration.split('+').length > 1 )
		{
			this.$toast.warning("Rebooking of spots only with single duration", 'Warning', { position: "topRight" });
			return;
		}
		
		var bookRequest = {"rotationId": 0, 
		                   "ids": ids };

		
		let that = this;
		let n = ids.length;
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//console.log( JSON.stringify(bookRequest));
		let businessTypeId = 0;
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+businessTypeId, bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                //console.log("done "+n+" rebooks in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
	                //that.getSpots();
	                //that.spotListTime++;       
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
	removeCopy(spots)
	{
		//alert( "doRebookSpots( " + spots.length+")");
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"copyId": 0, "ids": ids };
		let that = this;
		let n = ids.length;
		
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//console.log( JSON.stringify(bookRequest));
		//alert( JSON.stringify(bookRequest))
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/0", bookRequest)
	            .then( response => 
	            { 
	            	
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                
	                //alert( JSON.stringify(this.updatedSpots))
	                //console.log("done "+n+" rebooks in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
     
	            }).catch(e => {
	                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},

	saveUser( user) {
		if ( user ) 
     	{
     	    let that = this;
     		//console.log( userAPI+"/setUserSpotColumn/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	     	HTTP.put( userAPI+"/setUserSpotColumn/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, user)
		            .then( response => 
		            { 
		            	that.$toast.success("User "+that.user.login+" updated", 'Ok', { timeout: 1000, position: "topRight" });
		            }).catch(e => {
		                  that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	
		            });
		}
	},
	saveUserBookSettings( user) {
		if ( user ) 
     	{
     	    let that = this;
     	    user.bookSettings = this.options;
     		//console.log( userAPI+"/setUserBookSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	     	HTTP.put( userAPI+"/setUserBookSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, user)
		            .then( response => 
		            { 
		            	that.$toast.success("User "+that.user.login+" updated", 'Ok', { timeout: 1000, position: "topRight" });
		            }).catch(e => {
		                  that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	
		            });
		}
	},
	setTypeForSpots(spots, type)
	{
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"statusId": type.id, "ids": ids };
		let that = this;
		let n = spots.length;
		let time = new Date().getTime();
		//console.log(bngAPI+"/rebook/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id);
		//console.log( JSON.stringify(bookRequest));
		HTTP.put( bngAPI+"/rebook/booking/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/0", bookRequest)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
	                if ( response.data.spots && response.data.spots.length )
	                {
		                for ( let s in response.data.spots )
		                {
		                	response.data.spots[s].weekday = ((response.data.spots[s].weekday+1)%7)+1;
		                }
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
	                }
	                else
	                {
	                	that.getPlacementSpots(that.placement);
		                that.getSpots();
		                that.spotListTime++;
	                }
	                //console.log("done "+n+" rebookings in " + dur +"ms => "+(dur/n)+"ms/spot");
	                //that.getPlacementSpots(that.placement);
	                //that.getSpots();
	                //that.spotListTime++;       
	            }).catch(e => {
	                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	},
  
    getMainStyle()
    {
    	return "padding: 10pt 10pt 10pt 10pt; float: right; width: 75%; height: "+this.getHeight()+"px; overflow-y: scroll;";
    },
    filter( breakTypes )
    {
    	var copy = new Array;
    	for ( var bi in breakTypes )
    	{
    		let b = breakTypes[bi];
    		if ( this.breakTypeVisible( b.id))
    		{
    			copy.push(b);
    		}
    	}
    	return copy;
    }, 
    breakTypeVisible( typeId )
    {
    	return this.plcmBreakTypeIds.includes(typeId);
    },
    selectedSpots(unselect)
    {
    	var spots = new Array()
    	for ( var x in this.marked )
    	{
    		if ( this.marked[x] )
    		{
    		    if ( unselect )
    		    {
    		    	this.marked[x] = false;
    		    }
    			spots.push( x );
    		}
    	}
    	return spots;
    },
    bookMarkedIntern(myId, bookReq, api)
    {
    	if ( !this.businessType || !this.businessType.id )
    	{
    		that.showProgressBar = false;
    		that.$toast.error("No businesstype selected", 'Error', { position: "topRight" });
    		return;
    	}
        let n = bookReq.lines.length;
    	let time = new Date().getTime();
    	let that = this;
    	let copyId = ( this.copy && this.copy.id ) ? this.copy.id: 0;
    	bookReq.placement.duration = this.duration;
    	bookReq.status = {...this.selectedBookStatus};
		bookReq.view = this.inventoryPlacementResult.view; 
    	delete bookReq.status.label;
    	//console.log( bngAPI+"/book/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+copyId);
    	api.put( bngAPI+"/book/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placement.id+"/"+this.businessType.id+"/"+copyId+"/"+myId, bookReq)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
					that.showProgressBar = false;
					
					if ( that.inventoryPlacementResult && that.inventoryPlacementResult.gridPeriodResult )
					{
						that.updateAdBreaksInternalNew();
						/*
						for ( let si in response.data)
						{
							let spot = response.data[si];
							//alert( JSON.stringify( spot))	
							for (let ri in that.inventoryPlacementResult.gridPeriodResult)
							{
								let r = that.inventoryPlacementResult.gridPeriodResult[ri];
								if ( r.channel.id == spot.mediaId)
								{
									//alert( JSON.stringify( r.channel.name))	
									let day = r.days.find( r=>r.date==spot.date);
									//alert( JSON.stringify( day))	
									for (let gi in r.grids)
									{
										let grid = r.grids[ gi];
										if ( grid.gridId == spot.gridId)
										{
											//alert( JSON.stringify( grid))	
											for (let sli in grid.slots)
											{
												let slot = grid.slots[ sli];
												let daySlot = slot.slots.find( p=>p.dayNo==day.no && p.breakGridId == spot.breakGridId);
												if ( daySlot )
												{
													//alert( JSON.stringify( day) + JSON.stringify( daySlot))	
													daySlot.spotCount.bookingsOfPlacement++;
													//alert( JSON.stringify( daySlot))	
												}								
											}
										}
									}
								}
							}
						}
						*/
					}
					else
					{
		                //console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
		                that.getPlacementSpots(that.placement);
		                that.getSpots();
						
		                that.spotListTime++;       
	                }
	            }).catch(e => {
	            	   that.showProgressBar = false;
					   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
					   
	            });

    	
    },
	bookBriefingsIntern(myId, bookReq, api)
	    {
			let time = new Date().getTime();
	    	let that = this;
			
			let config = {
				name: "Booking_"+this.uploadData.name,
				placementId: this.placement.id,
				bookType: this.pbBookType,
				time: this.briefingOpt.time,
				weekTolerance: (1+this.briefingOpt.weekTolerance*0.01),
				addPaid: (this.briefingOpt.addPaid*100),
				addFree: (this.briefingOpt.addFree*100),
				wsId: myId,
				printInput: this.briefingOpt.printInput,
				briefingOpt: this.briefingOpt,
				bookRequest: bookReq,
				type: this.uploadData.type
			};
			
	    	api.put( bngAPI+"/bookBriefing/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, config)
		            .then( response => 
		            { 
						
						that.bookBriefingsSummary = response.data;
						that.showProgressBar = false;
						if ( that.bookBriefingsSummary.errors && that.bookBriefingsSummary.errors.length)
						{
							//
						}
						else
						{
							that.setPlacement( that.bookBriefingsSummary.placement, that.showPlacementGrid);
							
						}	
		                that.spotListTime++;      
						that.$forceUpdate();
		                
		            }).catch(e => {
		            	   that.showProgressBar = false;
						   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
						   
		            });

	    	
	    },
	action( what, myId, api)
    {
    	this[what](myId, this.bookReq, api)
    },
    bookMarked(bookReq)
  	{
    	if ( !this.businessType || !this.businessType.id )
    	{
    		this.$toast.error("No businesstype selected", 'Error', { position: "topRight" });
    		return;
    	}
  		this.bookReq = bookReq;
  	    this.pbAction = "bookMarkedIntern";
  	    this.pbTitle = "Performing bookings";
  	    this.showProgressBar = true;
  	},
	bookBriefings(bookReq, type)
  	{
		let that = this;
		this.$refs.confirm.confirm( {text: "Perform bookings", title: "Import", button1: "Cancel", button2: "Start"}).then(x => {      
	  		
			that.bookReq = bookReq;
			that.pbBookType = type;
	  	    that.pbAction = "bookBriefingsIntern";
	  	    that.pbTitle = "Performing bookings";
	  	    that.showProgressBar = true;
		});
  	},
  	deleteSpotInternal(myId, toDelete, api)
  	{
  	    let that = this;
  	    
		let time = new Date().getTime();
		var bookRequest = { "ids": toDelete };
		
  		//console.log( bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId);
		//console.log( JSON.stringify(toDelete ));
		api.put( bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+myId, bookRequest)
            .then( response => 
            { 
				if ( that.inventoryPlacementResult && that.inventoryPlacementResult.gridPeriodResult )
				{
					that.updateAdBreaksInternalNew();
				}
				if ( that.showPlacementSpots )
				{
	                let dur = (new Date().getTime()-time);
	                let deleted = response.data;
	                //console.log("done "+deleted.length+" cancellations in " + dur +"ms => "+(dur/deleted.length)+"ms/spot");
	                that.deletedSpots = deleted;
	                
	                that.spotsPlacementTotal -= deleted.length;
	                for ( var s in deleted )
	                {
	                	that.budgetPlacementTotal -= deleted[s].gross5;
	                	that.budgetPlacementPaidTotal -= deleted[s].gross5;
	                	if ( !deleted[s].gross5 && deleted[s].gross4)
	                	{
	                		that.budgetPlacementFreeTotal -= deleted[s].gross4;
	                	}
	                	if ( !deleted[s].gross5 && !deleted[s].gross4)
	                	{
	                		that.budgetPlacementFreeTotal -= deleted[s].gross3;
	                	}
	                	
	                	that.grpPlacementTotal -= deleted[s].grp;
	                }
				}
	            
	            that.showProgressBar = false;
                
            }).catch(e => {
            		that.showProgressBar = false;
                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
  	},
  	
  	deleteSpot(toDelete)
  	{
  	    if ( toDelete.length == 1 )
  	    {
  	    	this.deleteSpotInternal(0, toDelete, HTTP)
  	    }
  	    else
  	    {
			this.$refs.confirm.confirm( {text: "Perform cancellations", title: "Cancel "+toDelete.length+ " spots", button1: "No", button2: "Start"}).then(x => { 
		        this.bookReq = toDelete;
		  	    this.pbAction = "deleteSpotInternal";
		  	    this.pbTitle = "Performing cancellations";
		  	    this.showProgressBar = true;
			});
	  	}
  	},
	cancelMarked(spots)
	{
		if ( spots.length == 1 )
  	    {
  	    	this.cancelMarkedInternal(0, spots, HTTP)
  	    }
  	    else
  	    {
			this.$refs.confirm.confirm( {text: "Perform cancellations", title: "Cancel "+spots.length+ " slots", button1: "No", button2: "Start"}).then(x => { 
				this.bookReq = spots;
		  	    this.pbAction = "cancelMarkedInternal";
		  	    this.pbTitle = "Performing cancellations";
		  	    this.showProgressBar = true;
			});
	        
	  	}
	},	
    cancelMarkedInternal(myId, spots, api)
    {
        let n = spots.length;
    	let time = new Date().getTime();
    	let that = this;
		 
    	api.put( bngAPI+"/cancel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.selectedBookStatus.id+"/"+this.placement.id+"/"+this.inventoryPlacementResult.view+"/"+myId, spots)
	            .then( response => 
	            { 
	                let dur = (new Date().getTime()-time);
					if ( that.inventoryPlacementResult && that.inventoryPlacementResult.gridPeriodResult )
					{
						that.updateAdBreaksInternalNew();
					}
					else
					{
		                //console.log("done "+n+" cancellations in " + dur +"ms => "+(dur/n)+"ms/spot");
		                that.getPlacementSpots( that.placement);
		                that.getSpots();
		                that.spotListTime++;   
					}    
					that.showProgressBar = false;
	                
	            }).catch(e => {
					that.showProgressBar = false;
	                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });

    	
    },
    getBreakId( day, grid, adspace )
    {
    	if ( adspace.label )
    	{
    		let wd = this.weekdays[day];
        	let wdBit = Math.round(Math.log(wd)/Math.log(2));
    		return grid.adspacePerWeekday[wdBit].find( p=>p.no===adspace.no && p.label===adspace.label).id;
    	}
    },
    getKey(channel, grid, adspace, breaktype, day)
    {
		if ( adspace.id )
		{
			return getRawKeyById( adspace.id, null, this.isoDates[ day]);
		}

    	return getRawKey( channel, grid.detail.id, adspace.no, breaktype.id, this.isoDates[ day]);
    },
    
    getDates()
    {
    	this.dates = new Array();
    	this.isoDates = new Array();
    	this.weekdays = new Array();
    	this.weekdayBITS = new Array();
    	var bit = 1;
    	for ( var i = 0; i < 7; i++)
    	{
    		this.weekdayBITS[i] = bit;
    		bit *= 2;
    	}
    	
    	//alert( JSON.stringify( this.weekdayBITS));
    	let d = new Date(this.fromDate);
    	let untilDate = new Date(this.untilDate);
    	untilDate.setHours( d.getHours());
    	untilDate.setMinutes( d.getMinutes());
    	untilDate.setSeconds( d.getSeconds());
    	
    	let locale = sessionStorage.locale.replace('_','-');
    	var options = { month: 'numeric', day: 'numeric' };
    	var weekdayLabelARR = [];
      	let wdArr = JSON.parse(sessionStorage.weekdays);
      	for ( var w=1; w < 8; w++ )
      	{
        	let x = wdArr[w];
         	if ( x.length > 2 )
         	{
             	x = x.substring(0,2);
         	}
         	weekdayLabelARR.push( x);    
      	}
    	while ( d <= untilDate)
    	{
    	    let dateStr = d.toLocaleString( locale, options);
    		this.dates.push( dateStr );
    		this.isoDates[dateStr] = d.toISOString().split('T')[0];
    	    this.weekdays[dateStr] = this.weekdayBITS[d.getDay()];
    	    this.weekdayLabel[dateStr] = weekdayLabelARR[d.getDay()];
    	    //alert( d.getDay()+"/"+this.weekdayBITS[d.getDay()]+"::"+ this.weekdays[dateStr]+"-"+dateStr);
    		d.setDate(d.getDate()+1);
    	}
    	return this.dates;
    },
    dayStyle() {
    	return "float: left; width: 4%; ";
    },
    updateEntity(entity, record)
    {
    	if ( entity === 'Placement' )
    	{
    		this.setPlacementList( record);
    	}
    	
    },
    reload( entity, record)
    {
    	this.updateEntity( entity, record)
    },
    openEntity( event, entity, id )
     {
        let that = this;
        //console.log(fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+id)
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	      
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
     },
    closeEditor()
    {
         this.showEditor = false;
    },
    setGrid(grd) { 
       let that=this;
       that.selectedGrid = grd;
       this.treeHeight=50.0;
       this.placement= {};
       this.inventory= {};
       this.showPlacementGrid = false;
	   this.showPlacementGridOld = false;
       this.showPlacementSpots = false;
       let time=new Date().getTime()
       let inv3 = new Promise(function (resolve, reject) {
            //console.log(invAPI+"/findAllPlacements/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+grd.id);
	        HTTP.get( invAPI+"/findAllPlacements/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+grd.id)
	            .then( response => 
	            { 
	                // console.log("done in " + (new Date().getTime()-time)+"ms");
	                that.placements = response.data;
	             
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
    },
    
	weekdaySet(weekdays, date)
	{
		let wd = this.weekdays[date];
    	return ( weekdays & wd )
	},
	setPlacementList( plcm ) {
		this.setPlacement( plcm, false, true);
	}, 
	setSinglePlacement(id, filter)
	{
		alert("setSinglePlacement")
		getData( 'Placement', id ).then( p => { this.setPlacement( p, false, false, null, filter)}); 
	},
	getCappings( cappings, withEmpty)
	{ 
		let that = this;
		let list = [];
		for ( let capi in cappings )
        {
			let unit = cappings[ capi];
			let map = that.info.cappings.find(p=>p.cappingUnitId===unit.id);
			if ( !map )
			{
				map = {id: 0, cappingUnitId: unit.id, placementId: that.placement.id, capping: unit.capping };
			}
			map.capping = unit.capping;
			if ( withEmpty || map.capping)
			{
				list.push( map)
        	}
        }
		return list;

	},
	saveCappings( cappings)
	{ 
		let that = this;
		let list = that.getCappings( cappings, true);
		HTTP.put( reportAPI+"/saveCappings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id, list)
        .then( response => 
        { 
        	that.$toast.success("Cappings saved", 'Ok', { timeout: 1000, position: "topRight" });
        }).catch(e => {
        	showError( that.$toast, "save cappings", e)
        });

	},
	saveShares( shares)
	{ 
		let that = this;
		
		HTTP.put( reportAPI+"/saveShares/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id, shares)
        .then( response => 
        { 
			that.info.shares = response.data;
			
        	that.$toast.success("Shares saved", 'Ok', { timeout: 1000, position: "topRight" });
        }).catch(e => {
        	showError( that.$toast, "save shares", e)
        });

	},
	saveOptimizerSettings( optimParam)
	{ 
		let that = this;
		
		HTTP.put( reportAPI+"/saveOptimizerSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id, optimParam)
        .then( response => 
        { 
        	that.$toast.success("Optimiser settings saved", 'Ok', { timeout: 1000, position: "topRight" });
        }).catch(e => {
        	showError( that.$toast, "save Optimiser settings", e)
        });

	},
	keepLock()
	{
		let that = this;
		let plcmId = that.info.placement.id;
		
		HTTP.post( reportAPI+"/lock/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+plcmId)
	        .then( response => 
	        {
				//console.log( JSON.stringify( response.data))
			}).catch(e => {
				that.leaveScreen();
	            that.$toast.error(e.response, 'Error', { timeout: 2000, position: "topRight" });

	    });	 
	},
	leaveScreen()
	{
		let that = this;
		while ( keepLockTimer.length > 0 )
		{
		    clearInterval(keepLockTimer.pop());
		}
		that.$refs.confirm.confirm( {text: "Leave screen?", title: "Placement locked", button1: "Cancel", button2: "Leave"}).then(p => {
							        let x = goBack();
									that.$router.replace( x.path);
	    }).catch( o => {
			let repeat = that.info.lockTimeInMS / 2;
			if ( ! repeat || repeat < 2000 || repeat > 10000)
			{
				repeat = 2000;
			}
			if ( repeat > 10000)
			{
				repeat = 5000;
			}
			keepLockTimer.push( setInterval( that.keepLock, repeat));
		});
	},
	readPlcmInfo( response)
	{
		let that = this;
		let actualLimits = null;
		if ( that.info && that.info.feedLimitsActuals)
		{
			actualLimits = that.info.feedLimitsActuals;
		}
		that.info = response.data;
		if ( actualLimits )
		{
			that.$set( that.info, "feedLimitsActuals", actualLimits);
		}
		if ( that.info.shares )
		{
			that.info.shares = that.info.shares.sort((a,b)=>a.name.localeCompare(b.name))
		}
		
        if ( that.info.placement.optimizerSettings)
        {
        	that.optimParam = that.info.placement.optimizerSettings;
        }
		
		that.optimParam.safety = that.info.placement.safety ? that.info.placement.safety : 0;
		while ( keepLockTimer.length > 0 )
		{
		    clearInterval(keepLockTimer.pop());
		}
		let repeat = that.info.lockTimeInMS / 2;
		if ( ! repeat || repeat < 2000)
		{
			repeat = 2000;
		}
		if ( repeat > 10000)
		{
			repeat = 5000;
		}
		keepLockTimer.push( setInterval( that.keepLock, repeat));
        that.info.cappingSaved = [];
        for ( let capi in that.info.cappingUnits )
        {
        	let unit = that.info.cappingUnits[ capi];
        	let cap = that.info.cappings.find(p=>p.cappingUnitId===unit.id);
        	if ( cap )
        	{
        		unit.capping = cap.capping;
        		unit.mapId = cap.id;
        	}
        	that.info.cappingSaved.push( {...unit});
        }
        that.info.feedLimits = that.info.placement.channelLimits;
        that.user = response.data.user;
		if ( that.user != null )
		{
			if ( that.user.bookSettings && that.user.bookSettings.grpColor )
			{
				that.options = that.user.bookSettings
				if ( !that.options.freeColor)
				{
					that.options.freeColor = "#2b2";
				}
				if ( !that.options.fillerColor)
				{
					that.options.fillerColor = "#000";
				}
				if ( !that.options.fixedColor)
				{
					that.options.fixedColor = "#000";
				}
			}
			if ( that.options.gridViewFin === null )
			{
				that.options.gridViewFin = that.info.tenantGridViewFin;
			}
		}
        that.allTargetGroups =  response.data.allTargetGroups.map(p=>{ return {id: p.id, label: p.name, visible: true}});
		that.allTargetGroups.sort((a,b) => a.label.localeCompare( b.label))
        that.allBookStatus =  response.data.allBookStatus.map(p=>{ return {id: p.id, label: p.name, visible: true, inPlayList: p.inPlayList, isDefault: p.isDefault}});
        if ( response.data.placementStatus && response.data.placementStatus.statusId)
		{
			that.selectedBookStatus = that.allBookStatus.find( p=>p.id == response.data.placementStatus.statusId);
		}
		else if (!that.selectedBookStatus || !that.selectedBookStatus.id)
        {
        	that.selectedBookStatus = that.allBookStatus.find( p=>p.isDefault);
        }
       
        if ( that.info.placement && that.info.placement.targetgroupId)
        {
        	that.targetGroup = that.allTargetGroups.find( p=> p.id == that.info.placement.targetgroupId);
        }
	},
	loadPlacementInfo( plcm)
	{
		let that = this;
		let placementInfo = new Promise(function (resolve, reject) {
	        HTTP.get( placementServiceAPI+"/placementInfo/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+plcm.id+"/true")
	            .then( response => 
	            { 
					that.readPlcmInfo( response);
	                //alert( that.optimParam.safety + "/" +plcm.safety)
	                resolve (response.data);
	                
	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
						that.leaveScreen();
	                    reject ();
	            });
	      });
		return placementInfo;
	},
    setPlacement( plcm, showGrid, adjustDates, plcms, filter) { 
	   let that = this;
       that.placementIDs = plcm ? [plcm.id]:[];
       if ( plcms && plcms.length )
       {
    	   this.placements = plcms;
    	   that.placementIDs = plcms.map(p=>p.id);
       }
	   
       that.spotSearchFilter = filter;
       that.treeHeight=0;       
       this.placements = [];
       that.selectedGrid = {};
       that.gridValid = [];
       this.selectedGrid={}
       this.showPlacementSpots = false;
       that.inventory={};
       that.optimParam.safety = 0;
       
       let placementInfo = this.loadPlacementInfo( plcm );
       //alert( JSON.stringify( plcm));
	   placementInfo.then( info => {
		   that.campaign = {id: 0};   
	       that.placement = info.placement;
		  
		   if ( that.placement.media && that.placement.media.length == 1)
		   {
				that.selectedChannel = info.channels.find(p=>p.id===that.placement.media[0]);
		   }
	       that.businessTypes = info.businessTypes.map( p=>{return {id: p.id, label: p.name, paid: p.paid}});
	       if ( that.businessTypes && that.businessTypes.length >= 1)
	       {
	          that.businessType = that.businessTypes[0];   
	       }
	       
	       that.inventory = info.inventory;
	       plcm = info.placement;
		   let date1 = new Date(plcm.fromDate);
	       let date2 = new Date(plcm.untilDate);
	       let today = new Date();
		   today.setDate( today.getDate()-1);
		   that.currentWeekdays = plcm.weekdays;
	       
	       if ( date2 < today)
	       {
				that.fromDate = date1;
				that.untilDate = date2;
	       }
	       else
	       if ( adjustDates )
	       {
			   while ( date1 < today)
		       {
		       	  date1.setDate( date1.getDate()+1);
		       }
					   
	    	   that.fromDate = date1;
	    	   that.untilDate = date2;

	       }
	       //
	       
	       let dateFrom = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0];
	       let dateTo = momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0];
	       //alert( dateFrom);
	       that.productData = info.products;
	       that.product = that.productData.find( p=>p.id === plcm.productId)
	       
	       that.allCopyData = info.copies;
	       
	       that.copyData = new Array();
	       that.copy={}
	       that.allRotationData = info.rotations;
	      
	       that.rotationData = new Array();
	       that.rotation={}
		   if ( that.product )
	       {
	    	   that.allCopyData.sort(function(a,b) {
	    		    let v1 = (a.lock?0:1)+"."+a.received+"."+a.name;
		            let v2 = (b.lock?0:1)+"."+b.received+"."+b.name;
		            
		            if ( v1 > v2 )
		            {
		                
		                return -1;
		            }
		            if ( v1 < v2 )
		            {
		                return 1;
		            }
			     	return 0;
			     });
		       for ( var ci in that.allCopyData )
		       {
		       	  let copy = that.allCopyData[ci];
		       	  if ( copy.productIds.includes(that.product.id) )
		       	  {
		       	  		that.copyData.push( {id: copy.id, label: copy.name+"/"+copy.duration+"/"+copy.code+"/"+copy.received, locked: copy.lock?true:false });
		       	  }
		       }
		      // alert( JSON.stringify(that.copyData))
		       for ( var ri in that.allRotationData )
		       {
		       	  let rotation = that.allRotationData[ri];
		       	  if ( rotation.productId === that.product.id )
		       	  {
		       	  		that.rotationData.push( {id: rotation.id, label: rotation.name });
		       	  }
		       }
		   }
		  
	       if ( info.placement.rotationId )
	       {
	       		that.rotation = that.rotationData.find( p=>p.id==info.placement.rotationId)
	       }
	       that.plcmBreakTypes = new Array();
	       let breakTypeIds = new Array();
	       let breakTypes = new Array();
	       that.breakTypeData = info.allBreakTypes;
	       
	     
	       for ( var bt in that.breakTypeData )
           {
              let breakType = that.breakTypeData[bt];
              //alert(bt +"-->"+ JSON.stringify(breakType));
              if ( !that.placement.breakTypes || that.placement.breakTypes.length==0 || that.placement.breakTypes.includes( breakType.id) )
              {
              	breakTypes.push( {id: breakType.id, label: breakType.label});
              	breakTypeIds.push( breakType.id);
              }
           }
           //alert( JSON.stringify(breakTypes));
           that.$set( that, "breakTypeData", breakTypes);
           that.$set( that, "plcmBreakTypes", breakTypes);
           that.$set( that, "plcmBreakTypeIds", breakTypeIds);
           
		   //that.breakTypeData = that.plcmBreakTypes.slice();
		   //alert(JSON.stringify(that.breakTypeData));
		   that.$forceUpdate();
		   that.duration = plcm.duration;

		   if ( !that.duration || that.duration == '')
		   {
			    that.$toast.warning( tr('Placement') + " has no duration", 'Warning', { position: "topRight" });
		   }
		   if ( showGrid )
		   {
		   		that.getAdBreaksNew(plcm);
		   }
		   else
		   {
				that.getAdBreaksNew(plcm);
		   }
		   that.$set( that, "showPlacementGrid",  showGrid);
		   that.$set( that, "showPlacementSpots",  !showGrid);
		   that.filterStructure = {}
		   that.getAllFilters( that.placement);
		   
	       that.getPlacementSpots(that.placement, filter);

	   });   
       
    },
    setCampaign( campaign) { 
       let that = this;
       this.$set( this, "campaign",  campaign);
       this.$set( this, "showPlacementSpots",  false);
       that.treeHeight=20.0;       
       this.placements = [];
       that.selectedGrid = {};
       that.gridValid = [];
       this.selectedGrid={}
       
       this.showPlacementGrid = false;
       that.inventory={};
       that.duration = "";
	   
	   that.placement = {id: 0};   
       let date1 = new Date(campaign.fromDate);
       let date2 = new Date(campaign.untilDate);
       let today = new Date();
	   today.setDate( today.getDate()-1);
       
       while ( date1 < today)
       {
       	  date1.setDate( date1.getDate()+1);
       }
       if ( date2 < today)
       {
       	  date2 = new Date();
       }
       this.fromDate = date1;
       this.untilDate = date2;
       
       let dateFrom = this.fromDate.toISOString().split('T')[0];
       let dateTo = this.untilDate.toISOString().split('T')[0];
       let campaignInfo = new Promise(function (resolve, reject) {
	        HTTP.get( reportAPI+"/campaignInfo/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+campaign.id)
	            .then( response => 
	            { 
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for placementInfo", e);
	                    reject ();
	            });
	      });
	   campaignInfo.then( info => {
	       //alert( dateFrom);
	       that.productData = info.products;
	       that.product = 0;
	       for ( var pi in info.products )
	       {
	       	  let prod = info.products[pi];
	       	  if ( ! that.product)
	       	  {
	       	  		that.product = prod;
	       	  }
	       }
	       that.allCopyData = info.copies;
	       that.copyData = new Array();
	       that.copy={}
	       that.allRotationData = info.rotations;
	       that.rotationData = new Array();
	       that.rotation={}
	       if ( that.product )
	       {
		       for ( var ci in that.allCopyData )
		       {
		       	  let copy = that.allCopyData[ci];
		       	  if ( copy.productId === that.product.id )
		       	  {
		       	  		that.copyData.push( {id: copy.id, label: copy.name+"/"+copy.duration+"/"+copy.code });
		       	  }
		       }
		       for ( var ri in that.allRotationData )
		       {
		       	  let rotation = that.allRotationData[ri];
		       	  if ( rotation.productId === that.product.id )
		       	  {
		       	  		that.rotationData.push( {id: rotation.id, label: rotation.name });
		       	  }
		       }
		   }
	       // alert( JSON.stringify(that.allCopyData));
	       that.plcmBreakTypes = new Array();
	       that.plcmBreakTypeIds = new Array();
	       that.breakTypeData = info.allBreakTypes;
	       for ( var bt in that.breakTypeData )
           {
              let breakType = that.breakTypeData[bt];
              that.plcmBreakTypes.push( breakType);
              that.plcmBreakTypeIds.push( breakType.id);
           }
		   that.breakTypeData = that.plcmBreakTypes.slice();
		   //alert(JSON.stringify(that.breakTypeData));
		   
		   that.spotListTime++;
	       that.$set( that, "showPlacementSpots",  true);
	   });
    },
    optimize( plcm)
    {
		let that =this;
		if ( !that.placement || !that.placement.id )
	    {
	    	this.$toast.error("No placement selected", 'Error', { position: "topRight" });
	    	return;
	    }
	    if ( !that.businessType || !that.businessType.id )
	    {
	    	this.$toast.error("No businesstype selected", 'Error', { position: "topRight" });
	    	return;
	    }
		this.$refs.confirm.confirm( {text: "Run optimiser", title: "Optimiser", button1: "Cancel", button2: "Start"}).then(x => {
	  	    that.pbAction = "optimizeInternal";
	  	    that.pbTitle = "Running optimiser";
	  	    that.showProgressBar = true;
	    });
    },
    optimizeInternal( myId, toDelete, api)
    {
		
    	let that = this;
    	let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
        let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
        
        let options = {...that.optimParam};
        options.allFilters = that.filterStructure;
		
        options.cappings = that.getCappings( that.info.cappingUnits, false);
        options.shares = that.info.shares;
        options.weekLimits = that.info.weekLimits;
		options.feedLimits = that.info.feedLimits;
		
		
		options.weekShareLimits = that.info.weekShareLimits;
		
        options.weekLimits.usePacing = false;
		options.duration = this.duration;
        //alert( JSON.stringify(options.allFilters))
        //console.log( JSON.stringify( options))
		
        delete options.allFilters.id;
        delete options.allFilters.placementId;
        delete options.allFilters.tenantId;
        delete options.allFilters.name;
        delete options.allFilters.inventory;
        delete options.allFilters.amount;
        delete options.allFilters.type;
        delete options.allFilters.tolerance;
        delete options.allFilters.addNegative;
        delete options.allFilters.lUserId;
        delete options.allFilters.extraData;
        delete options.allFilters.key;
        delete options.allFilters.lUpdate;
        //alert( JSON.stringify( options.allFilters))
    	api.post( bngAPI+"/optimizePlacementNEW/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+that.placement.id+"/"+that.businessType.id+"/"+myId, options )
 		 .then( response => 
          {  
        	  this.showProgressBar = false;
        	  
			  //that.info.feedLimitsActuals = response.data.feedLimitsActuals;
			  that.$set( that.info, "feedLimitsActuals", response.data.feedLimitsActuals);

        	  //that.getAdBreaksNew( that.placement, false );
			  if (that.optimParam.performBookings && that.showPlacementGrid && that.placement)
	          {
				  that.info.weekLimits = response.data.weekLimits;
				  
				  if ( that.inventoryPlacementResult && that.inventoryPlacementResult.gridPeriodResult )
				  {
					 if ( !that.inventoryPlacementResult.overview)
					 {
					 	 that.updateAdBreaksInternalNew();
					 }
					 else
					 {
						that.getAdBreaksNew(that.placement, false, false);
					 }
				  }
				  else
				  {
					that.getPlacementSpots(that.placement).then(p=>{that.getAdBreaksNew(that.placement, false, true);})
			  	  }
		      }
              else
              {
	        	  that.markBreaks = [];
	        	  
	        	  if (that.optimParam.performBookings)
	        	  {

					  that.getAdBreaksNew( that.placement, false );
	        	  }
	        	  else
	        	  {
	        		  that.markBreaks = response.data.bookingResult;
	        	  	 // alert( JSON.stringify(that.markBreaks))
	        	  }
		          
        	  }
        	  
          }).catch(e => {
        	  this.showProgressBar = false;
			  console.log(JSON.stringify( options.allFilters))
              showError( that.$toast, "optimizePlacement", e)
              
          });
    },
    checkShares()
    {
    	let that = this;
    	let options = {...that.optimParam};
        options.allFilters = that.filterStructure;
        options.cappings = that.getCappings( that.info.cappingUnits, false);
        options.shares = that.info.shares;
       
        //alert( JSON.stringify(options.allFilters))
        //console.log( JSON.stringify( options))
        
        delete options.allFilters.id;
        delete options.allFilters.placementId;
        delete options.allFilters.tenantId;
        delete options.allFilters.name;
        delete options.allFilters.inventory;
        delete options.allFilters.amount;
        delete options.allFilters.type;
        delete options.allFilters.tolerance;
        delete options.allFilters.addNegative;
        delete options.allFilters.lUserId;
        delete options.allFilters.extraData;
        delete options.allFilters.key;
        delete options.allFilters.lUpdate;
        //alert( JSON.stringify( options.shares))
    	HTTP.post( bngAPI+"/checkShares/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id, options )
 		 .then( response => 
          {  
			  
        	  that.sharesInfo = response.data;
        	  
          }).catch(e => {
        	  this.showProgressBar = false;
              showError( that.$toast, "optimizePlacement", e)
              
          });
    },
    checkWeekLimits()
	{
		/*
		for ( let li in this.info.weekLimits.limits)
	    {
			let lim = this.info.weekLimits.limits[ li];
			if (  !lim.percentage || isNaN(lim.percentage))
			{
				lim.percentage = 0;
			}
	    }
		*/
	},
	showAdBreaksNew(plcm, dayGrid) 
	{
		this.getAdBreaksNew(plcm, dayGrid);
		this.showPlacementGrid = true; 
	},
	getAdBreaksNew(plcm, dayGrid, spots) 
	{
		
		if ( spots || this.info.placementType.filler || (!this.options.compactView && !(this.selectedChannel && this.selectedChannel.id)))
		{
			this.showSpots(plcm);
		}
		else
		{
	    	this.placement = plcm;
	    	this.pbDayGrid = dayGrid?true:false;
	    	this.pbAction = "getAdBreaksInternalNew";
	    	this.pbTitle = "Reading grid structure";
	  	    this.showProgressBar = true;
		}
	},
    getAdBreaksInternalNew(myId, bookReq, api) {
       let plcm = this.placement;
       this.getDates();
       this.placements = [];
       let that = this;
       this.marked = new Array();
       this.markedSpots = 0;
	   this.markedGRP = 0;
	   this.markedBudget = 0;
       that.showPlacementGridOld = false;
	   
       //that.showPlacementSpots = false;
       let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
       let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
       
       that.gridValid = [];
       // GET INVENTORY 
       // GET GRIDS
	   let dFrom = new Date(dateFrom);
       let dTo = new Date(dateTo);
       let time = new Date().getTime();
       let filterStructure = {... this.filterStructure};
       filterStructure.placement = {... that.placement};
       filterStructure.dayGrid = this.pbDayGrid;
	   filterStructure.duration = this.duration;
	   
	   if ( this.selectedChannel && this.selectedChannel.id )
	   {
			filterStructure.placement.media = [ this.selectedChannel.id];
	   }
	   
	   this.checkWeekLimits();
	   
	   filterStructure.placement.weekLimits = that.info.weekLimits;
	   if ( that.targetGroup && that.targetGroup.id )
	   {
	      filterStructure.placement.targetgroupId = that.targetGroup.id;
	   }
	   delete filterStructure.mediaKindId;
       let startTime = new Date().getTime();
	      
	      let grd = new Promise(function (resolve, reject) {
		  let gridView = that.options.gridViewFin ? "Financial": "TX";
		  let call = that.options.compactView ? "findInventorySlotsOverview" : "findInventorySlotsNew"; 
		  
		  api.post( invAPI+"/"+call+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+sessionStorage.timezoneId+"/null/"+gridView+"/"+myId, filterStructure )
		         		 .then( response => 
		                  {
							that.inventoryPlacementResult = response.data;
							that.updateWeekLimits( that.inventoryPlacementResult.limits)
							that.info.weekLimits = that.inventoryPlacementResult.limits;
							that.info.channelLimits = that.inventoryPlacementResult.channelLimits;
							//that.showPlacementGrid = true;
							that.showProgressBar = false;
						  }).catch(e => {
	                        showError( that.$toast, "getting ad grid", e);
							that.showProgressBar = false;
	                    });
			
  
    	})
	  },
	  updateAdBreaksInternalNew() {
		 
	     let plcm = this.placement;
	     this.getDates();
	     //console.log("getDates()");
	     
	     this.placements = [];
	     let that = this;
	     this.marked = new Array();
	     this.markedSpots = 0;
		 this.markedGRP = 0;
		 this.markedBudget = 0;
	     that.showPlacementGridOld = false;
	   
	     //that.showPlacementSpots = false;
	     let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
	     let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
	     
	     that.gridValid = [];
	     // GET INVENTORY 
	     // GET GRIDS
	     let dFrom = new Date(dateFrom);
	     let dTo = new Date(dateTo);
	     let time = new Date().getTime();
		
	     let filterStructure = {... this.filterStructure};
	     filterStructure.placement = that.placement;
		 this.checkWeekLimits();
		 filterStructure.placement.weekLimits = that.info.weekLimits;
	     filterStructure.dayGrid = this.pbDayGrid;
	     filterStructure.duration = this.duration;
		 delete filterStructure.mediaKindId;
	     let startTime = new Date().getTime();
	     let inputStructure = { filters: filterStructure, toUpdate: that.inventoryPlacementResult }; 
		 //console.log( JSON.stringify( that.inventoryPlacementResult)); 
	     let grd = new Promise(function (resolve, reject) {
			HTTP.post( invAPI+"/findInventorySlotsUpdate/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+sessionStorage.timezoneId+"/0", inputStructure  )
				 .then( response => 
				  {
					that.inventoryPlacementResult = response.data;
					
					that.info.weekLimits = that.inventoryPlacementResult.limits;
					that.info.channelLimits = that.inventoryPlacementResult.channelLimits;
					that.updateWeekLimits( that.inventoryPlacementResult.limits)
					//that.showPlacementGrid = true;
					//that.showProgressBar = false;
				  }).catch(e => {
				    showError( that.$toast, "getting ad grid", e);
					that.showProgressBar = false;
				});
			

	  	})
	},
    hasAdBreak( channel, grid, adspaceNo, breaktypeId, day)
    {
    	let wd = this.weekdays[day];
    	if ( !(grid.detail.weekdays & wd) )
    	{
    		return false;
    	}
    	//alert( this.isoDates[day]);
    	let d = new Date( this.isoDates[day]);
    	//alert( d);
    	let pfrom = new Date( this.placement.fromDate);
    	//alert( pfrom+ " - " + (d < pfrom));
    	if ( d < pfrom)
    	{
    		return false;
    	}
    	let pTo = new Date( this.placement.untilDate);
    	if ( d > pTo)
    	{
    		return false;
    	}
    	pfrom = new Date( grid.detail.fromDate);
    	//alert( pfrom+ " - " + (d < pfrom));
    	if ( d < pfrom)
    	{
    		return false;
    	}
    	pTo = new Date( grid.detail.untilDate);
    	if ( d > pTo)
    	{
    		return false;
    	}
    	return true;
    },
    saveAllFilters(filters)
    {
    	let that = this;
    	if ( filters.placementFilter )
    	{
    		delete filters.placementFilter.mediaKindId;
    	}
    	if ( filters.clientFilter )
    	{
    		delete filters.clientFilter.mediaKindId;
    	}
    	if ( filters.productFilter )
    	{
    		delete filters.productFilter.mediaKindId;
    	}
		delete filters.mediaKindId;
    	HTTP.put( inventoryInlineAPI+"/saveAllFilterForBookings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, filters)
		 .then( response => 
  		{
  			that.filterStructure = response.data;
  			that.$toast.success("Filters saved", 'Ok', { timeout: 1000, position: "topRight" });
  			return that.filterStructure;
  		}).catch(e => {
           
           showError( that.$toast, "saveAllFilters", e)
        });
    },
    
	getAllFilters(placement)
	{
    	if ( placement && placement.id)
    	{
	    	let that = this;
	    	let productId = this.product && this.product.id ? this.product.id : 0;
	    	let copyId = this.copy && this.copy.id ? this.copy.id : 0;
	    	if ( that.filterStructure && that.filterStructure.placement )
	    	{
	    		let reload = false;
	    		if (!that.filterStructure.placement || that.filterStructure.placement.id !== placement.id)
	    		{
	    			reload = true;
	    		}
	    		if (!that.filterStructure.product || that.filterStructure.product.id !== productId)
	    		{
	    			reload = true;
	    		}
	    		if (!that.filterStructure.copy || that.filterStructure.copy.id !== copyId)
	    		{
	    			reload = true;
	    		}
	    		if ( !reload)
	    		{
	    			//return that.filterStructure;
	    		}
	    	}
	    	//alert(inventoryInlineAPI+"/getAllFilterForBookings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+productId+"/"+copyId)
			HTTP.post( inventoryInlineAPI+"/getAllFilterForBookings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+productId+"/"+copyId, placement)
			 .then( response => 
	   		{
	   			that.filterStructure = response.data;
	   			//alert( JSON.stringify( that.filterStructure.placementFilter))
	   			return that.filterStructure;
	   		}).catch(e => {
	            
	            showError( that.$toast, "getAllFilters", e)
	    	});
    	}
	},
  	durationTotal(durString) 
  	{
  		var parts = durString.split('+');
  		let total = 0;
  		for ( let i in parts )
  		{
  			total += parseInt( parts[i]);
  		}
		
  		return total;
  	},
    getSpots( spotsInGrid)
    {
		
        let that = this;
        let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
        let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
        that.spotsDay = new Array();
        that.spotsGrid = new Array();
        that.spotsUnplacedBreak = [];
        that.consumedGrid = new Array();
        that.consumedBreak = new Array();
        that.spotsBreakTotal = new Array();
        that.invalidSpots = new Array();
        that.spotsBreak = new Array();
        that.allBookingStats = new Array();
        that.spotsPlacement = 0;
        var validKeys = new Array();
    	let time = new Date().getTime();
    	
	    let spotLoader = new Promise(function (resolve, reject) {
	        //console.log(bngAPI+"/findAllSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo);
	        //console.log( JSON.stringify(that.mediaSet));
	        // ----------------------------------------
	        // get valid keys
	        // ----------------------------------------
	        for ( var c in that.mediaSet) 
	        {
	        	let channel = that.mediaSet[c];
	        	//console.log( "channel> " + channel );
		        for ( var d in that.dates) 
		        {
		            var day = that.dates[d];
		            
		            for ( var slot in that.timeSlots[channel])
		            {
		            	var timeSlot = that.timeSlots[channel][slot];
		            	
		            	for ( var igrid in that.gridData[channel][timeSlot])
		            	{
		            		var grid = that.gridData[channel][timeSlot][igrid];
							
		            		for ( var iadspace in grid.adspace)
		            		{
		            			var adspace = grid.adspace[iadspace];
		            			let types = adspace.breakTypes;
		            			for ( var ibType in types)
		            			{
		            				var bType = types[ibType];
		            				if ( that.hasAdBreak( channel, grid, adspace.no, bType.id, day) )
		            				{
			            				var key = that.getKey(channel, grid, adspace, bType, day);
			            				validKeys.push(key);
			            			}
		            				//console.log( key );
		            			}
		            	 	}
		            	}
		            }
		        }
		    }
	        let spotReader = null;
	        if ( !spotsInGrid)
	        {
	            spotReader = new Promise(function (resolve, reject) {
	        	HTTP.post( bngAPI+"/findAllSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo, that.mediaSet)
	        		 .then( response => 
	                 {
	                	 spotsInGrid = response.data;
	                	 resolve( response.data);
	                 });
	            });
	        	spotReader.then( data => spotsInGrid=data);
	        }
	        else
	        {
	        	spotReader = new Promise(function (resolve, reject) {
		        	resolve( spotsInGrid);
	        	});
	        }
	        
	        spotReader.then( data => {
	        	    spotsInGrid = data;
	       
		    
                    let dur = (new Date().getTime()-time);
	                //console.log("loading grid "+spotsInGrid.spots.length+" spots in " + dur +"ms");
                    let spots = spotsInGrid.spots;
                    let stati = spotsInGrid.bookStatus;
                    let unplacedStats = new Array();
                    let inPlaylist = new Array();
                    for ( var iStat in stati )
                    {
                    	let status = stati[ iStat];
                    	if ( status.valid && !status.inPlayList )
                    	{
                    		unplacedStats.push( status.id );
                    	}
                    	if ( status.valid && status.inPlayList )
                    	{
                    		inPlaylist.push( status.id );
                    	}

                    	if ( status.setOnMove )
                    	{
                    		that.allBookingStats.push( { id: status.id, label: status.name });
                    	}	
                    }
                    
                    //console.log( JSON.stringify(unplacedStats));
                    let invalidSpotIDs = new Array();
                    //console.log("that.duration> " + that.duration); 
                    let totalDur = that.durationTotal(that.duration);
                    //console.log("totalDur> " + totalDur);
                    for ( var iSpot in spots)
                    {
                        let spot = spots[iSpot];
                        let day = spot.mediaId+"."+spot.date;
                        let gridKey = day+"."+spot.gridId;
                        let breakKey = spot.breakGridId? getRawKeyById( spot.breakGridId, null, spot.date): getRawKey(spot.mediaId, spot.gridId, spot.breakNo, spot.breakTypeId, spot.date);
						
                        //console.log( gridKey + "-> "+ breakKey);
                    	if ( !that.spotsDay[ day ] )
                    	{
                    	 	that.spotsDay[ day ] = 0;
                    	}
                    	if ( !that.spotsGrid[ gridKey ] )
                    	{
                    	 	that.spotsGrid[ gridKey ] = 0;
                    	}
                    	if ( !that.spotsUnplacedBreak[ breakKey ] )
                    	{
                    	 	that.spotsUnplacedBreak[ breakKey ] = 0;
                    	}
                    	if ( !that.consumedGrid[ gridKey ] )
                    	{
                    	 	that.consumedGrid[ gridKey ] = totalDur;
                    	}
                    	if ( !that.consumedBreak[ breakKey ] )
                    	{
                    	 	that.consumedBreak[ breakKey ] = totalDur;
                    	}
                    	if ( !that.spotsBreakTotal[ breakKey ] )
                    	{
                    	 	that.spotsBreakTotal[ breakKey ] = 1;
                    	}
                    	if ( inPlaylist.includes( spot.statusId ) )
                    	{
                    		that.consumedGrid[ gridKey ] += spot.duration;
                    		//console.log("totalDur> " + that.consumedGrid[ gridKey ]);
                    		that.consumedBreak[ breakKey ] += spot.duration;
                    	}
                    	that.spotsBreakTotal[ breakKey ] += 1;
                    	//console.log( "that.consumedGrid[ gridKey ] > " + that.consumedGrid[ gridKey ]);
                    	if ( !that.spotsBreak[ breakKey ] )
                    	{
                    	 	that.spotsBreak[ breakKey ] = 0;
                    	}
                    	
                    	//console.log( "that.placementId > " + spot.placementId+"/"+that.placement.id+ " " + (spot.placementId === that.placement.id));
                    	if ( spot.placementId === that.placement.id )
                    	{
							//console.log( JSON.stringify( unplacedStats) +":"+ spot.statusId); 
                    		if ( !unplacedStats.includes( spot.statusId))
                    		{
								that.spotsDay[ day ]++;
	                    		that.spotsGrid[ gridKey ]++;
								//console.log( "GK> "+ gridKey + "->" + that.spotsGrid[ gridKey ])
	                    		that.spotsBreak[ breakKey ]++;
								
	                    		that.spotsPlacement++;
 
                    		}
                    		else
                    		{
                    			that.spotsUnplacedBreak[ breakKey ] = that.spotsUnplacedBreak[ breakKey ] + 1;
                    		}
                    		if ( !validKeys.includes(breakKey) || unplacedStats.includes( spot.statusId))
	                        {
	                        	invalidSpotIDs.push( spot.id);
	                        	
	                        }
                    	}
                    	//console.log( "that.spotsPlacement > " + that.spotsPlacement);
                    }
                    that.$set( that, "spotsPlacement", that.spotsPlacement)
                    //alert(that.spotsPlacement)
                    if ( invalidSpotIDs && invalidSpotIDs.length )
                    {
                    	HTTP.post( bngAPI+"/enrichSpotsByIDList/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placement.id, invalidSpotIDs)
                    	.then( response => 
                 			{
                 				that.invalidSpots = response.data[0].spots;
                 				that.displayInvalidSpots = that.invalidSpots;
                 				that.invalidSpotsChanged++;
                 				that.showProgressBar = false;
                 			}).catch(e => {
                 				showError( that.$toast, e, "enrichSpotsByIDList")
                 				that.showProgressBar = false;
		                    });	  
                    } else {
                        
                    	that.displayInvalidSpots = new Array();
                    	that.invalidSpotsChanged++;
                    }
                    //that.$forceUpdate();
                    that.markedSpots=0;

                    that.spots = new Date().getTime();
                    
                    that.countPlacementGrid++;
                    that.showProgressBar = false;
                    resolve (spotsInGrid);
	        });     

	      });

    },
    getPlacementSpots(plcm, filter)
    {
    	let that = this;
        that.spotsPlacement = 0;
		that.spotsPlacementTotal = 0;
		that.budgetPlacementTotal = 0;
		that.budgetPlacementPaidTotal = 0;
		that.budgetPlacementFreeTotal = 0;
		that.rateCardPlacementTotal = 0;
		that.spotsPlacementTotalFree = 0;
		that.grpPlacementTotal = 0;
	      let spotLoader = new Promise(function (resolve, reject) { 
	        let searchRequest = { placementIds: that.placementIDs, filter: {} };
	    	if ( that.spotSearchFilter )
	    	{
	    		searchRequest.filter = that.spotSearchFilter
	    	}
	        HTTP.post( bngAPI+"/findAllPlacementRevenue/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, searchRequest)
        		 .then( response => 
                 {  
                    var revData = response.data;
                    that.info.allBreakTypes = [];
                    for ( let iSpot in revData)
                    {
                        let line = revData[iSpot];
                        
                        that.spotsPlacementTotal += line.bookings;
                        that.spotsPlacementTotalFree += line.frees;
                        //that.spotsPlacement += line.bookings;
                        that.budgetPlacementTotal += line.revenuePaid;
                        that.budgetPlacementPaidTotal += line.revenuePaid;
                        that.budgetPlacementFreeTotal += line.revenueTotal-line.revenuePaid;
                        that.grpPlacementTotal += line.grp;
                        that.rateCardPlacementTotal += line.revenueRatecardPaid;
                        
                    }
                    that.$set( that, "spotsPlacementTotal", that.spotsPlacementTotal);
					HTTP.post( placementServiceAPI+"/placementOverview/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+plcm.id)
			        		 .then( response => 
			                 {  
								that.inventoryPlacementResult = response.data;
								that.updateWeekLimits( that.inventoryPlacementResult.limits)
								that.info.weekLimits = that.inventoryPlacementResult.limits;
								that.info.channelLimits = that.inventoryPlacementResult.channelLimits;
			                    resolve (that.spotsPlacementTotal);
			                        
			                 }).catch(e => {
			                	    showError(that.$toast, "loading data for placements", e);
			                        reject ();
			               });
                        
                 }).catch(e => {
                	    showError(that.$toast, "loading data for placements", e);
                        reject ();
                    });
	      });
		  return spotLoader;
    },
    fmtTimePart( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
   },
   setRotation()
    {
    	if ( this.rotation && this.rotation.id)
    	{
    		this.copy = {};
    	}
    },
    setTargetGroup()
    {
    	
    },
   setCopy(c) {
   	  this.copy = c;
   	  if ( this.copy && this.copy.id)
   	  {
   	  		//this.rotation = {};
   	  }
   	  for ( var ci in this.allCopyData )
       {
       	  let copy = this.allCopyData[ci];
       	  if ( copy.id === c.id )
       	  {
       	  		this.duration = ""+copy.duration; // needs to be a string
       	  		break;
       	  }
       }
   },
   setProduct(p) {
   	  this.placement.productId=p.id; 
   	  this.copy = {}
   	  this.copyData = this.allCopyData.filter( p => p.productId === p.id).map(p=>{ return {id: p.id, label: p.name+"/"+p.duration+"/"+p.code }})
   
   },
   setBreakTypes(p) {
   	  
   },
   downloadBriefingReport( )
   {
		let downloadResponse = this.bookBriefingsSummary.file;
	    this.forceFileDownload( downloadResponse.file, downloadResponse.filename);
   },
   downloadConf(plcm) { 
   	    	this.placement = plcm;
   	    	this.pbAction = "downloadConfInternal";
   	    	this.pbTitle = "Creating confirmation";
   	  	    this.showProgressBar = true;
   },
   downloadConfInternal( myId, spots, api )
    {
    	let that = this;
    	api.post( opsAPI+"/downloadConf/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.placement.id+"/1/"+myId)
	        .then( response => 
	        { 
               
               let downloadResponse = response.data;
           	   that.showProgressBar = false;
               that.$refs.pdfView.open(downloadResponse.file, downloadResponse.filename)
           		
        	}).catch(e => {that.showProgressBar = false; showError( that.$toast, "file download error", e)})

    },

    downloadSpotList( placementId, report )
    {
    	let that = this;
		let dateFrom = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0];
	    let dateTo = momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0];
    	HTTP.post( opsAPI+"/downloadSpotList/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+placementId+"/"+dateFrom+"/"+dateTo, report)
	        .then( response => 
	        { 
               
               let downloadResponse = response.data;
			   that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))

    },
    storeSettings() 
	{
		try
		{
			let obj = restore();
			if ( !obj )
			{
				obj = {};
			}
			obj.placementId = this.placement.id;
			obj.untilDate = this.untilDate;
			obj.fromDate = this.fromDate;
			//alert( JSON.stringify( obj))
	    	store( obj);
		} catch(e) { showError( this.$toast, "storeSettings()", e);  }
	},
  },
  beforeDestroy() {
	  
	  this.storeSettings();
	  while ( keepLockTimer.length > 0 )
	  {
		clearInterval(keepLockTimer.pop());
	  }
  },
  created() {
    let that = this; 
    this.treeHeight=90.0; this.displayInvalidSpots = new Array(); 
    let adjustDates = true;
    
    if ( this.$route.params && this.$route.params.reqRec )
    {

    	if ( this.$route.params.to )
 	    {
 	    	this.untilDate = new Date( this.$route.params.to);
 	    	adjustDates = false;
 	    }
 	    if ( this.$route.params.from )
 	    {
 	    	this.fromDate = new Date( this.$route.params.from);
 	    	adjustDates = false
 	    }
 	   if ( this.$route.params.reqRec )
	    {
	    	//getData( 'Client', this.$route.params.clientId ).then( p => { this.setClient(p)}); 
	    	//alert( this.$route.params.reqRec)
	    	let x = [];
	    	let filter = this.$route.params.filter ? JSON.parse(this.$route.params.filter): {};
	    	let copy = JSON.parse( this.$route.params.reqRec);
	    	delete copy.fromDateISO;
	    	delete copy.untilDateISO;
	    	delete copy.clientId;
	    	for ( let x in copy)
	    	{
	    		filter[x] = copy[x];
	    	}
	    	//alert( JSON.stringify(this.$route.params.reqRec))
	    	getPlacements( JSON.parse( this.$route.params.reqRec) )
	    	    .then( p => {
	    	    	
	    	    	that.setPlacement( p[0], false, false, p, filter)
	    	     })
	    	
	    }
    }
    else
    {
	    if ( this.$route.query.to )
	    {
	    	this.untilDate = new Date( this.$route.query.to);
	    
	    	adjustDates = false;
	    }
	    if ( this.$route.query.from )
	    {
	    	this.fromDate = new Date( this.$route.query.from);
	    	adjustDates = false
	    }
	  
	    if ( this.$route.query.channel )
	    {
	    	this.preSelectMedia = this.$route.query.channel;
	    }
	    if ( this.$route.query.id )
	    {
	    	getData( 'Placement', this.$route.query.id ).then( p => { this.setPlacement( p, ! this.$route.query.list, adjustDates)}); 
	    }
	    else
	    {
		    let restoreView = restore();
		   
	      	if ( restoreView && restoreView.placementId )
	      	{
	      		if ( restoreView.fromDate )
	      		{
	      			this.fromDate = new Date( restoreView.fromDate);
	    	    	adjustDates = false
	      		}
	      		if ( restoreView.untilDate )
	      		{
	      			this.untilDate = new Date( restoreView.untilDate);
	    	    	adjustDates = false
	      		}
				
	      		getData( 'Placement', restoreView.placementId ).then( p => { this.setPlacement( p, true, adjustDates)}); 
	      	}
	    }
    }
    
    this.getOpenThisModule();
  },
  watch: {
    displayInvalidSpots: function() {
	    //console.log("BOM.watch(displayInvalidSpots:"+this.displayInvalidSpots.length+")")
	},
	selectedChannel: function() {
		
		this.showAdBreaksNew( this.placement)
	},
	copy: function() {
		this.getAllFilters( this.placement)
	},
	product: function() {
		this.getAllFilters( this.placement)
	},
	
  	plcmBreakTypes: function() { 
  	  if ( this.plcmBreakTypes )
  	  {
  	      this.plcmBreakTypeIds = new Array();
	  	  for ( var bi in this.plcmBreakTypes )
		                {
		                  let breakType = this.plcmBreakTypes[bi];
		                  
		                  
		                  if ( !this.placement.breakTypes || this.placement.breakTypes.length==0 || this.placement.breakTypes.includes( breakType.id) )
		                  {
		                  	this.plcmBreakTypeIds.push( breakType.id);
		                  	//alert(bi +"-->"+ JSON.stringify(this.plcmBreakTypeIds));
		                  }
		                }
      }
  	}
  	
  }
}
</script>
<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.xtransition {
 	transition: all .2s;
   -webkit-transition: all .2s;
 }
.transitionOptimizer {
  transition: all .4s;
   -webkit-transition: all .4s;
}
.settingsBlock {
    position: relative;
    display: block;
	padding-top: 2pt;
	padding-bottom: 2pt;
	height: calc(40vH);
	border-bottom: 1px solid grey;
	border-top: none;
}

.myButton {
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonSmall {
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    font-size: 6pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonVSmall {
    padding: 1px 2px;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    font-size: 8pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonS {
    padding: 1px 2px;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #ccc;
    color: rgb(0, 0, 0);
    margin-top: 2pt;
    margin-right: 2pt;
    margin-bottom: 2pt;
}  
.smallButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 14pt;
    width: 50pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonLight {
	//width: 18%;
    padding: 3px 4px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #fff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.xtransitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.folderStyle {
	border: 1px solid grey;
	border-bottom: none;
	cursor: pointer;
	height: 19pt !important;
	display: inline-block;
	float: left;
	color: #000;
	border-radius: 0.3em 0.8em 0 0;
	background-color: #eef;
}
.folderStyle:hover {
	background-color: #bbb;
}
.folderStyle:where([active]) {
	background-color: #bbf;
	
}
.BMday {
  display: table-cell;
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 78% !importrant;
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: transparent; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionBlock {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: block; 
	color: #666; 
	background-color: transparent; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-flex;
}
.dontWrapIB {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-block;
  height: 23pt;
  padding-top: 1pt;
}
.dontWrap90 {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
  width: 100%;
  display: block;
}
.smallText {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextXS {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 12pt;
  display: inline-block;
}
.smallTextXXS {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  display: inline-block;
}
.smallText2 {
  
  overflow: hidden;
  text-align: right;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: table-cell;
}
.smallText2x { 
  width: 100pt !important;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallText3x { 
  width: 70% !important;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 12pt;
  display: inline-block;
}
.smallText4x { 
  width: 70% !important;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  display: inline-block;
}
.smallText2bx { 
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
  height: 14pt;
  padding-top: 3pt;
}
.smallText2rx { 
  overflow: hidden;
  text-align: right;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
  height: 14pt;
  padding-top: 3pt;
}
.smallText50 {
  width: 50%;
}
.smallText60 {
  width: 60%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallText25 {
  width: 25%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextFull {
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextTab {
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
  width: 100%;
}
.smallTextSlider {
  width: 60%;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.noInventory {
  display: block; 
  float: top;
  margin: 0pt;
  color: #888;
  border-bottom: 1px dotted grey; 
  padding: 0pt; 
  font-size: 9pt;
  height: 12pt;
  width: 99%; 
  overflow: hidden; 
  text-overflow: ellipsis !important; 
  white-space:nowrap;
  cursor: pointer;
}
.inventory {
  display: block; 
  float: top;
  margin: 0pt;
  border-bottom: 1px dotted grey; 
  padding: 0pt; 
  font-size: 9pt;
  height: 12pt;
  width: 99%; 
  overflow: hidden; 
  text-overflow: ellipsis !important; 
  white-space:nowrap;
  cursor: pointer;
}
.inventory:hover {
  background-color: #888;
  color: #ddd;
}
.smallDistance {
  margin-top: 2pt;
}
.BMadbreak {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #ccc;
  background-color: #fff; 
  text-align: center;
  height: 100%;
  margin: 0pt;

  font-size: 7pt;
}
.BMadbreakMarked {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #f0c;
  background-color: #bcc; 
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  font-size: 7pt;
}
.BMnoadbreak {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #bbb;
  background-color: #eee;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  font-size: 7pt;
}
.BMbreak:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMadbreak:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMadbreakMarked:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMheader { 
  display: block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 8pt; 
  font-weight: bold;
  
  //overflow-x: hidden;
  //overflow-y: hidden;
 
  white-space: nowrap; /* Don't forget this one */ 
  //text-overflow: ellipsis;  
  
  //border-bottom: 1pt solid #ccc;
  background-color: #eef; 
 }
.BMheaderInv {
	color: transparent;
}
.BMprogram {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
	//border: 1pt solid #fcc; 
	position:relative;
	z-index: 1;
}
.BMtime {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
}
.BMinfo {
	background-color: #58dada; 
}
.BMbreak {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
}
.BMheadLine {
  color: #fff !important;
  background-color: #58dada !important; 
}
.todrag {
 display: block;
 width: 100%; 
 height: 100%;
}
.BMheaderDay { 
  font-size: 7pt; 
  display: table-cell;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  align-items: center;
  justify-content: center;  
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
 }
 .BMappHead { 
    display: block;
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.selection {
 	border-top: 1pt solid #888; 
    border-right: 1pt solid #888; 
 }
 .colored {
  
  background-color: #bbf; 
  padding-top: 2pt;
  padding-left: 4pt;
 }
 .colored:hover {
  cursor: pointer;
  color: #444 !important;
  background-color: #aaf; 
 }
 .iButton {
    background-color: transparent;
    border: none;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.iButton:hover {
    color: #fff !important;
  	background-color: rgb(88, 218, 218);; 
}  
.right
{
float: right !important;

align-items: right !important;;
justify-content: right !important;; 
text-align: right !important;
}
</style>
