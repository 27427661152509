 <template>
  <input v-if="value===null || value===''" size="1" @focus="$event.target.select()" 
         :style="getBGStyle3()+'border: .5pt solid #ccc; background-color: #aaa; color: #fff; width: 30pt;font-size: 10pt; height: 14pt; text-align: right;'" 
         class='myInput' type='number' v-model="value"/>
  <input v-else size="1" @focus="$event.target.select()" 
         :style="getBGStyle3()+'border: .5pt solid #ccc; width: 30pt;font-size: 10pt; height: 14pt; text-align: right;'" 
         class='myInput' type='number' v-model="value"/>
</template>

<script>

import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
export default {
  name: "NUMERIC_INPUTFIELD",
   components : {
	   
  },
  props: {
     value: Object
  },
  data(){
    return {
    	getAppStyle, getBGStyle2, getBGStyle3
     }
    },
    directives:{
       
      },
    methods: {
        
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  			
  },
   watch:
   {
   	   value: function() { this.$emit("input", this.value); this.$emit("changed", this.value)}
   }
}
</script>
<style  scoped>
.myInput {
	height: 16pt;
	margin-bottom: 4pt;
	background-color: transparent;
}
</style>